import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { useBlocker, useNavigate } from "react-router-dom";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import { Label } from "../Common/Label/Label";
import OpenDatePicker from "../Common/OpenDatePicker/OpenDatePicker";
import PhoneNumberInput from "../Common/PhoneNumberInput/PhoneNumberInput";
import OpenUniversitySelect from "../Common/OpenTagSelect/OpenUniversitySelect";
import HelperService from "../../Services/HelperService";
import OpenCitySelect from "../Common/OpenTagSelect/OpenCitySelect";
import ExitConfirmModal from "./ExitConfirmModal";
import AsyncUnivarsityPaginationSelect from "../Common/OpenTagSelect/AsyncUnivarsityPaginationSelect";
import editIcon from "../../assets/images/icon-edit.svg";
import AddSchoolAddress from "./AddSchoolAddress";

interface propData {
  type: number;
  isUpdate: Function;
  tab?: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

interface parentForm {
  parent_type: string;
  is_exact_date: string;
  legal_guardian_date: string;
  legal_guardian_year: string;
  parent_living: string;
  deceased_date: string;
  prefix: string;
  other_prefix: string;
  parent_first_name: string;
  parent_middle_name: string;
  parent_surname: string;
  former_surname: string;
  parent_suffix: string;
  parent_email: string;
  is_parent_phone_number: string;
  parent_phone_number: string;
  parent_occupation_id: string;
  parent_other_occupation: string;
  parent_employment_status: string;
  parent_currently_employed: string;
  name_current_employer: string;
  parent_highest_education: string;
  institution_attended: any;
  parent_college_id: any;
  degrees_parent_received: string;
  parent_highest_degree: string;
  year_parent_degree_received: string;
  parent_phone_code: any;
}

const PrefixList = [
  { id: "Dr", value: "Dr." },
  { id: "Mr", value: "Mr." },
  { id: "Ms", value: "Ms." },
  { id: "Mrs", value: "Mrs." },
  { id: "Mx", value: "Mx." },
  { id: "Other", value: "Other" },
];

const suffinxOptions = [
  { id: "Jr.", value: "Jr." },
  { id: "Sr.", value: "Sr." },
  { id: "II", value: "II" },
  { id: "III", value: "III" },
  { id: "IV", value: "IV" },
  { id: "V", value: "V" },
  { id: "VI", value: "VI" },
  { id: "VII", value: "VII" },
  { id: "VIII", value: "VIII" },
  { id: "IX", value: "IX" },
  { id: "X", value: "X" },
];

const parentEmployment = [
  { id: "EMPLOYED", value: "Employed" },
  { id: "UNEMPLOYED", value: "Unemployed" },
  { id: "RETIRED", value: "Retired" },
  { id: "SELF_EMPLOYED", value: "Self Employed" },
];

const parentHighSchool = [
  {
    id: "COMPLETED_HIGH_SCHOOL_LESS",
    value: "Completed some high school or less",
  },
  { id: "GRADUATED_HIGH_SCHOOL", value: "Graduated from high school or GED" },
  { id: "SOME_COLLEGE_EDUCATION", value: "Some college education" },
  { id: "ASSOCIATE_DEGREE", value: "Associate's Degree (Two-Year)" },
  {
    id: "GRADUATE_BACHELOR_DEGREE",
    value: "Graduated from college (Bachelor's Degree)",
  },
  {
    id: "GRADUATE_MASTER_DEGREE",
    value: "Graduated from Graduate program (Master's Degree)",
  },
  {
    id: "GRADUATED_PROFESSIONAL_PROGRAM",
    value: "Graduated Professional program (MBA, MD, JD, etc)",
  },
  {
    id: "GRADUATED_DOCTORATE_PROGRAM",
    value: "Graduated from Doctorate program (PhD or higher)",
  },
];

const parentDegreeCount = [
  { id: "0", value: "0" },
  { id: "1", value: "1" },
  { id: "2", value: "2" },
  { id: "3", value: "3" },
  { id: "4", value: "4" },
  { id: "5", value: "5" },
];

const parentDegreeType = [
  { id: "ASSOCIATE_DEGREE", value: "Associate degree (undergraduate)" },
  { id: "BACHELOR_DEGREE", value: "Bachelor's degree (undergraduate)" },
  { id: "MASTER_DEGREE", value: "Master's degree (graduate)" },
  { id: "DOCTORAL_DEGREE", value: "Doctoral degree (graduate)" },
  { id: "PROFESSIONAL_DEGREE", value: "Professional degree" },
];

const parentAddress = [
  { id: "HOME_ADDRESS", value: "Same as my home address" },
  { id: "MAILING_ADDRESS", value: "Same as my mailing address" },
  { id: "DIFFERENT", value: "A different address" },
];

const Parent = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, resetField, formState: { errors } } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const emailRegex = /^[\w-\.+_-]+@([\w-]+\.)+[\w-]{2,4}$/;
  const [openSchoolAddress, setOpenSchoolAddress] = useState<boolean>(false);
  const [occupationOption, setOccupationOption] = useState<any[]>([]);
  const [countryOptions, setCountryOptions] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState<any[]>([]);
  const [addressIndex, setAddressIndex] = useState<number>(-1);
  const [editAddress, setEditAddress] = useState<any>({});
  const array = [1, 2, 3, 4, 5];
  const [confirmExit, setConfirmExit] = useState(false);
  const [isChange, setIsChange] = useState<boolean>(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return ((props.tab == "1stparent" && props.type == 1) || (props.tab == "2ndparent" && props.type == 2)) && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (((props.tab == "1stparent" && props.type == 1) || (props.tab == "2ndparent" && props.type == 2)) && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, props.tab);
    } else {
      props.isFormDirty(false, props.tab);
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (((props.tab == "1stparent" && props.type == 1) || (props.tab == "2ndparent" && props.type == 2)) && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if ((props.tab == "1stparent" && props.type == 1) || (props.tab == "2ndparent" && props.type == 2)) {
      reset({});
      getUserDetail();
      getOccupationList();
      getCountryOption();
    }
  }, [props.tab]);

  const getUserDetail = () => {
    WebService.getAPI({
      action: `get/parent/detail?type=PARENT_${props.type}`,
    })
      .then((res: any) => {
        reset(res.parent);
        res.parent?.country_id && getStateOption(res.parent.country_id);
        if (res.parent.parent_educations && res.parent.parent_educations.length > 0) {
          setValue("institution_attended", res.parent.parent_educations.length);
          res.parent.parent_educations.map((item: any, i: number) => {
            setValue(`parent_college_id${i + 1}`, { ...item.parent_college, ...{ value: item.parent_college.id, label: item.parent_college.name } });
            setValue(`parent_highest_degree${i + 1}`, item.parent_highest_degree);
            setValue(`school_country_id${i + 1}`, item.parent_college.country_id);
            setValue(`school_state_id${i + 1}`, item.parent_college.state_id);
            setValue(`school_state_code${i + 1}`, item.parent_college.state_code);
            setValue(`school_city${i + 1}`, item.parent_college?.city_obj?.name);
            setValue(`school_street${i + 1}`, item.parent_college.street);
            setValue(`school_zip${i + 1}`, item.parent_college.zip);
            setValue(`school_address_1${i + 1}`, item.parent_college.address_1);
            setValue(`school_address_2${i + 1}`, item.parent_college.address_2);
            setValue(`school_address_3${i + 1}`, item.parent_college.address_3);
            if (item.parent_college.country_id == 239) {
              setValue(`school_address${i + 1}`,
                `${item.parent_college?.address || ""}, ${item.parent_college?.city_obj?.name || ""
                }, ${item.parent_college?.state_obj?.code || ""}, ${item.parent_college?.zip || ""
                }`
              );
            } else {
              setValue(
                `school_address${i + 1}`,
                `${item.parent_college?.address_1 || ""}, ${item.parent_college?.city_obj?.name || ""
                }, ${item.parent_college?.zip || ""}`
              );
            }
          });
        } else {
          setValue("institution_attended", 0);
        }
        setValue("parent_living", res.parent.parent_living === "YES" ? true : false);
      })
      .catch((e) => { });
  };

  const getOccupationList = () => {
    WebService.getAPI({ action: `get/occupation/list` })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          const usIndex = res.list.findIndex((item: any) => item.id == 44);

          if (usIndex !== -1) {
            const updatedList = [
              res.list[usIndex],
              ...res.list.slice(0, usIndex),
              ...res.list.slice(usIndex + 1),
            ];
            setOccupationOption(
              updatedList.map((item: any) => {
                return { ...item, ...{ value: item.name } };
                //label: item.name,
              })
            );
          } else {
            setOccupationOption(
              res.list.map((item: any) => {
                return { ...item, ...{ value: item.name } };
              })
            );
          }
        }
      })
      .catch(() => { });
  };

  const onSave = async (data: any) => {
    const ParentCollege = [];
    for (let i in array) {
      if (array[i] <= watchAllFields.institution_attended) {
        const ParentCollegeID = data[`parent_college_id${array[i]}`].value;
        const ParentDegreeReceived = data[`degrees_parent_received${array[i]}`];
        const ParentHighestDegree = data[`parent_highest_degree${array[i]}`];
        const YearDegressReceived = data[`year_parent_degree_received${array[i]}`];
        const schoolCountryID = data[`school_country_id${array[i]}`];
        const schoolStateID = data[`school_state_id${array[i]}`];
        const schoolStateCode = data[`school_state_code${array[i]}`];
        const schoolCity = data[`school_city${array[i]}`];
        const schoolStreet = data[`school_street${array[i]}`];
        const schoolZip = data[`school_zip${array[i]}`];
        const schoolAddress1 = data[`school_address_1${array[i]}`];
        const schoolAddress2 = data[`school_address_2${array[i]}`];
        const schoolAddress3 = data[`school_address_3${array[i]}`];

        ParentCollege.push({
          parent_college_id: ParentCollegeID,
          degrees_parent_received: ParentDegreeReceived,
          parent_highest_degree: ParentHighestDegree,
          year_parent_degree_received: YearDegressReceived,
          school_country_id: schoolCountryID,
          school_state_id: schoolStateID,
          school_state_code: schoolStateCode,
          school_city: schoolCity,
          school_street: schoolStreet,
          school_zip: schoolZip,
          school_address_1: schoolAddress1,
          school_address_2: schoolAddress2,
          school_address_3: schoolAddress3,
        });
      }
    }

    data.parent_living = data.parent_living ? "YES" : "NO";

    const requestBody = {
      ...data,
      parent_educations: ParentCollege,
    };

    await WebService.postAPI<any>({
      action: `save/parent/detail?type=PARENT_${props.type}`,
      body: requestBody,
      id: props.type == 1 ? "parent1_save_btn" : "parent2_save_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  const resetOtherFields: any = (id: number) => {
    array.map((item: any) => {
      if (item > id) {
        setValue(`degrees_parent_received${item}`, "");
        setValue(`parent_highest_degree${item}`, "");
        setValue(`year_parent_degree_received${item}`, "");
        setValue(`parent_phone_code${item}`, "");
        setValue(`school_country_id${item}`, "");
        setValue(`school_state_id${item}`, "");
        setValue(`school_state_code${item}`, "");
        setValue(`school_city${item}`, "");
        setValue(`school_street${item}`, "");
        setValue(`school_zip${item}`, "");
        setValue(`school_address_1${item}`, "");
        setValue(`school_address_2${item}`, "");
        setValue(`school_address_3${item}`, "");
      }
    });
  };

  const getCountryOption = () => {
    WebService.getAPI({ action: "countries" })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          const usIndex = res.list.findIndex(
            (item: any) => item.name === "United States"
          );

          if (usIndex !== -1) {
            const updatedList = [
              res.list[usIndex],
              ...res.list.slice(0, usIndex),
              ...res.list.slice(usIndex + 1),
            ];
            setCountryOptions(
              updatedList.map((item: any) => {
                return { ...item, ...{ value: item.name } };
                //label: item.name,
              })
            );
          } else {
            setCountryOptions(
              res.list.map((item: any) => {
                return { ...item, ...{ value: item.name } };
              })
            );
          }
        }
      })
      .catch((e: any) => { });
  };

  const getStateOption = (id: string) => {
    WebService.postAPI({ action: `states`, body: { country_id: id } })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          setStateOptions(
            res.list.map((item: any) => {
              return { ...item, ...{ value: item.name } };
            })
          );
        }
      })
      .catch((e: any) => { });
  };

  const getZipCodeData = (zip: string) => {
    WebService.getAPI({ action: `zipcode?zipcode=${zip}` })
      .then((res: any) => {
        if (res.result) {
          setValue("city", res.result.city_id);
          setValue("state_id", res.result.city.state_id);
        }
      })
      .catch((e: any) => { });
  };

  const resetAddress = () => {
    setValue("state_id", "");
    setValue("city", "");
    resetField("zipcode");
    setValue("street", "");
    setValue("apartment", "");
    setValue("address_1", "");
    setValue("address_2", "");
    setValue("address_3", "");
  };

  const onChangeSchool = (e: any, index: number) => {
    if (e && e.value) {
      if (isNaN(e.value)) {
        setValue(`school_address${index}`, "");
        setValue(`school_country_id${index}`, "");
        setValue(`school_state_id${index}`, "");
        setValue(`school_state_code${index}`, "");
        setValue(`school_city${index}`, "");
        setValue(`school_street${index}`, "");
        setValue(`school_zip${index}`, "");
        setValue(`school_address_1${index}`, "");
        setValue(`school_address_2${index}`, "");
        setValue(`school_address_3${index}`, "");
        setAddressIndex(index)
        setTimeout(() => { setOpenSchoolAddress(true); }, 100);
      } else {
        setValue(`school_address${index}`, `${e?.address + "," || ""} ${e?.city_obj?.name ? `${e?.city_obj?.name},` : ""} ${e?.state_obj ? e?.state_obj?.code : e?.state || ""}, ${e?.zipcode || e?.zip || ""} `
        );
      }
    }
  };

  const onClose = (type: boolean, data: any) => {
    if (type && data && addressIndex > -1) {
      setValue(`school_country_id${addressIndex}`, data.country_id);
      setValue(`school_state_id${addressIndex}`, data.state_id);
      setValue(`school_state_code${addressIndex}`, data.state_code);
      setValue(`school_city${addressIndex}`, data.city);
      setValue(`school_street${addressIndex}`, data.street);
      setValue(`school_zip${addressIndex}`, data.zip);
      setValue(`school_address_1${addressIndex}`, data.address_1);
      setValue(`school_address_2${addressIndex}`, data.address_2);
      setValue(`school_address_3${addressIndex}`, data.address_3);
      if (data.country_id == 239) {
        setValue(`school_address${addressIndex}`, `${data?.street || ""}, ${data?.city || ""}, ${data?.state_code || ""}, ${data?.zipcode || ""}`);
      } else {
        setValue(`school_address${addressIndex}`, `${data?.address_1 || ""}, ${data?.city || ""}, ${data?.zipcode || ""}`);
      }
    };
    setOpenSchoolAddress(false);
    setAddressIndex(-1);
    setEditAddress({});
    setIsChange(true);
  };

  const onEditSchoolAddress = (index: number) => {
    let obj = {
      parent_college_id: watchAllFields[`parent_college_id${index}`],
      school_country_id: watchAllFields[`school_country_id${index}`],
      school_state_id: watchAllFields[`school_state_id${index}`],
      school_state_code: watchAllFields[`school_state_code${index}`],
      school_city: watchAllFields[`school_city${index}`],
      school_street: watchAllFields[`school_street${index}`],
      school_zip: watchAllFields[`school_zip${index}`],
      school_address_1: watchAllFields[`school_address_1${index}`],
      school_address_2: watchAllFields[`school_address_2${index}`],
      school_address_3: watchAllFields[`school_address_3${index}`],
    };
    setAddressIndex(index);
    setEditAddress(obj);
    setOpenSchoolAddress(true);
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: '624px' }}>
            <Form className="form-style-2">
              <h4 className="font-medium font-20 mb-3 pb-1">
                {props.type == 1 ? "1st Parent" : "2nd Parent"}
              </h4>
              <Form.Group className="mb-4">
                <Form.Label>
                  {props.type == 1 ? "1st" : "2nd"} parent's relationship to you
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Check
                  type="radio"
                  label="Mother"
                  value={"MOTHER"}
                  id="iHave"
                  {...register("parent_type", { required: false })}
                />
                <Form.Check
                  type="radio"
                  label="Father"
                  value={"FATHER"}
                  id="iPlan"
                  {...register("parent_type", { required: false })}
                />
                <Form.Check
                  type="radio"
                  label="Legal guardian"
                  value={"LEGAL_GUARDIAN"}
                  id="iPlan"
                  {...register("parent_type", { required: false })}
                />
                <Form.Check
                  type="radio"
                  label="Stepparent"
                  value={"STEP_PARENT"}
                  id="iPlan"
                  {...register("parent_type", { required: false })}
                />
                <Form.Check
                  type="radio"
                  label="Grandparent"
                  value={"GRAND_PARENT"}
                  id="iPlan"
                  {...register("parent_type", { required: false })}
                />
              </Form.Group>

              <Form.Group className="mb-4 col-lg-8">
                <Form.Check
                  key={new Date().getTime() + props.type}
                  type="checkbox"
                  id={`${new Date().getTime() + props.type}`}
                  label=" Please check if this parent is deceased"
                  checked={watchAllFields.parent_living}
                  {...register("parent_living", { required: false })}
                />
              </Form.Group>

              {watchAllFields.parent_living && (
                <Form.Group className="mb-4 col-lg-12">
                  <Form.Label>Select deceased date</Form.Label>
                  <Controller
                    control={control}
                    name="deceased_date"
                    rules={{ required: false }}
                    render={({ field }: any) => (
                      <OpenDatePicker
                        type="date"
                        selected={watchAllFields.deceased_date}
                        // minData={new Date()}
                        maxData={new Date()}
                        onChange={(e: any) => field.onChange(e)}
                      />
                    )}
                  />
                </Form.Group>
              )}

              {watchAllFields.parent_type == "LEGAL_GUARDIAN" && (
                <Form.Group className="mb-4 col-lg-12">
                  <Form.Label>
                    The date parent {props.type == 1 ? "1st" : "2nd"} bacame
                    legal guardian
                  </Form.Label>
                  <div className="text-secondary font-12 mb-2">
                    If you don't know the exact date, you can provide the year
                  </div>
                  <Form.Check
                    type="switch"
                    id="joinNetwork"
                    label="I don't know the exact date"
                    className="font-18 mb-2"
                    {...register("is_exact_date", { required: false })}
                  />
                  {watchAllFields.is_exact_date ? (
                    <div key={new Date().getTime()}>
                      <Controller
                        control={control}
                        name="legal_guardian_year"
                        rules={{ required: false }}
                        render={({ field }: any) => (
                          <OpenDatePicker
                            type="year"
                            selected={watchAllFields.legal_guardian_year}
                            // minData={new Date()}
                            maxData={new Date()}
                            onChange={(e: any) => field.onChange(e)}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div key={new Date().getTime()}>
                      <Controller
                        control={control}
                        name="legal_guardian_date"
                        rules={{ required: false }}
                        render={({ field }: any) => (
                          <OpenDatePicker
                            type="date"
                            selected={watchAllFields.legal_guardian_date}
                            maxData={new Date()}
                            onChange={(e: any) => field.onChange(e)}
                          />
                        )}
                      />
                    </div>
                  )}
                </Form.Group>
              )}

              <Form.Group className="mb-4 col-lg-12">
                <Form.Label>Prefix</Form.Label>
                <Controller
                  control={control}
                  name="prefix"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <>
                      <OpenSelect
                        sakey="prefix"
                        isSearchable={true}
                        options={PrefixList}
                        selected={watchAllFields.prefix}
                        onChange={(e: any) => {
                          field.onChange(e.id);
                        }}
                      />
                    </>
                  )}
                />
              </Form.Group>

              {watchAllFields.prefix === "Other" && (
                <Form.Group className="mb-4 col-lg-12">
                  <Form.Label> Specify other Prefix </Form.Label>
                  <Form.Control
                    type="text"
                    className={`${watchAllFields.other_prefix ? "border-brand" : ""
                      }`}
                    placeholder="Enter Other prefix"
                    {...register("other_prefix", {
                      required: false,
                    })}
                  />
                </Form.Group>
              )}

              <Form.Group className="mb-4 col-lg-12">
                <Form.Label>
                  {" "}
                  Legal first name of your {props.type == 1
                    ? "1st"
                    : "2nd"}{" "}
                  parent<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.parent_first_name ? "border-brand" : ""
                    }`}
                  placeholder=""
                  {...register("parent_first_name", {
                    required: false,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-4 col-lg-12">
                <Form.Label> Middle initial</Form.Label>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.parent_middle_name ? "border-brand" : ""
                    }`}
                  placeholder=""
                  {...register("parent_middle_name", {
                    required: false,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-4 col-lg-12">
                <Form.Label>
                  {" "}
                  Legal last name of your {props.type == 1 ? "1st" : "2nd"}{" "}
                  parent<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.parent_surname ? "border-brand" : ""
                    }`}
                  placeholder=""
                  {...register("parent_surname", {
                    required: false,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-4 col-lg-12">
                <Form.Label> Former last/family/surname (if any)</Form.Label>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.former_surname ? "border-brand" : ""
                    }`}
                  placeholder=""
                  {...register("former_surname", {
                    required: false,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Suffix</Form.Label>
                <Controller
                  control={control}
                  name="parent_suffix"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <OpenSelect
                      sakey="parent_suffix"
                      isSearchable={true}
                      options={suffinxOptions}
                      selected={watchAllFields.parent_suffix}
                      onChange={(e: any) => {
                        field.onChange(e.id);
                      }}
                    />
                  )}
                />
                {/* <div className="text-secondary font-12 mb-2">
                  Open and member colleges may communicate about your account,
                  information relevant to the college admissions process, and
                  your college experience using the contact information you
                  provide below.{" "}
                </div> */}
              </Form.Group>

              <Form.Group className="mb-4 col-lg-12">
                <Form.Label>Address</Form.Label>
                <Controller
                  control={control}
                  name="parent_address"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <>
                      <OpenSelect
                        sakey="parent_address"
                        isSearchable={true}
                        placeholder="Choose an option"
                        options={parentAddress}
                        selected={watchAllFields.parent_address}
                        onChange={(e: any) => {
                          field.onChange(e.id);
                        }}
                      />
                    </>
                  )}
                />
              </Form.Group>

              {watchAllFields.parent_address == "DIFFERENT" && (
                <>
                  <Form.Group className="mb-4">
                    <Form.Label>Country</Form.Label>
                    <Controller
                      control={control}
                      name="country_id"
                      // rules={{ required: true }}
                      render={({ field }: any) => (
                        <div className="form-style">
                          <OpenSelect
                            sakey="country_id"
                            isSearchable={true}
                            selected={watchAllFields.country_id}
                            options={countryOptions}
                            onChange={(e: any) => {
                              field.onChange(e.id);
                              if (e.id == 239) {
                                getStateOption(e.id);
                              }
                              resetAddress();
                            }}
                          />
                        </div>
                      )}
                    />
                    {errors.country_id && (
                      <div className="login-error">
                        <Label
                          title={"Please Select Home Country."}
                          modeError={true}
                        />
                      </div>
                    )}
                  </Form.Group>
                  <>
                    {watchAllFields && watchAllFields.country_id && (
                      <div>
                        {watchAllFields.country_id == 239 ? (
                          <>
                            <div className="d-flex gap-2">
                              <Form.Group className="mb-4 w-100">
                                <Form.Label>
                                  Street<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className={`${watchAllFields.street ? "border-brand" : ""
                                    }`}
                                  value={watchAllFields.street}
                                  {...register("street", {
                                    required: false,
                                  })}
                                />
                              </Form.Group>

                              <Form.Group className="mb-4 w-100">
                                <Form.Label>Apartment</Form.Label>
                                <Form.Control
                                  type="text"
                                  className={`${watchAllFields.apartment
                                    ? "border-brand"
                                    : ""
                                    }`}
                                  value={watchAllFields.apartment}
                                  {...register("apartment", {
                                    required: false,
                                  })}
                                />
                              </Form.Group>
                            </div>
                            <div className="d-flex gap-3">
                              <Form.Group className="mb-4 w-100">
                                <Form.Label>Zip code</Form.Label>
                                <Form.Control
                                  type="text"
                                  className={`${watchAllFields.zipcode ? "border-brand" : ""
                                    }`}
                                  onKeyPress={(e) =>
                                    HelperService.allowOnlyNumericValue(e)
                                  }
                                  {...register("zipcode", { required: false })}
                                  maxLength={5}
                                  onBlur={(e: any) => {
                                    e.target.value.length > 4 &&
                                      getZipCodeData(e.target.value);
                                  }}
                                />
                                {errors.zipcode && (
                                  <div className="login-error">
                                    <Label
                                      title={"Please Enter Zipcode."}
                                      modeError={true}
                                    />
                                  </div>
                                )}
                              </Form.Group>
                              <Form.Group className="mb-4 w-100">
                                <Form.Label>City</Form.Label>
                                {/* <Controller
                                    control={control}
                                    name="city"
                                    rules={{ required: false }}
                                    render={({ field }: any) => (
                                      <OpenCitySelect country_id={watchAllFields.country_id} selected={watchAllFields.city} onChange={(e: any) => { field.onChange(e.id); setValue("state_id", e.state_id) }} placeholder={"Select City"} />

                                    )}
                                  /> */}
                                <Form.Control
                                  type="text"
                                  className={`${watchAllFields.city ? "border-brand" : ""
                                    }`}
                                  value={watchAllFields.city}
                                  {...register("city", { required: false })}
                                  onKeyPress={(e) =>
                                    HelperService.allowOnlyAlphabetsValues(e)
                                  }
                                />
                                {errors.city && (
                                  <div className="login-error">
                                    <Label
                                      title={"Please Enter City."}
                                      modeError={true}
                                    />
                                  </div>
                                )}
                              </Form.Group>

                              <Form.Group className="mb-4 w-100">
                                <Form.Label>State</Form.Label>
                                <Controller
                                  control={control}
                                  name="state_id"
                                  rules={{ required: false }}
                                  render={({ field }: any) => (
                                    <OpenSelect
                                      sakey="state_id"
                                      isSearchable={true}
                                      options={stateOptions}
                                      selected={watchAllFields.state_id}
                                      onChange={(e: any) => {
                                        field.onChange(e.id);
                                      }}
                                      placeholder={"Select State"}
                                    />
                                  )}
                                />
                                {errors.state_id && (
                                  <div className="login-error">
                                    <Label
                                      title={"Please Select State."}
                                      modeError={true}
                                    />
                                  </div>
                                )}
                              </Form.Group>
                            </div>

                          </>
                        ) : (
                          <>
                            <Form.Group className="mb-4">
                              <Form.Control
                                type="text"
                                className={`${watchAllFields.address_1 ? "border-brand" : ""
                                  }`}
                                value={watchAllFields.address_1}
                                {...register("address_1", {
                                  required: false,
                                })}
                                placeholder="Address line 1*"
                              />
                            </Form.Group>
                            <Form.Group className="mb-4 col-lg-12">
                              <Form.Control
                                type="text"
                                className={`${watchAllFields.address_2 ? "border-brand" : ""
                                  }`}
                                value={watchAllFields.address_2}
                                {...register("address_2", {
                                  required: false,
                                })}
                                placeholder="Address line 2"
                              />
                            </Form.Group>
                            <Form.Group className="mb-4 col-lg-12">
                              <Form.Control
                                type="text"
                                className={`${watchAllFields.address_3 ? "border-brand" : ""
                                  }`}
                                value={watchAllFields.address_3}
                                {...register("address_3", {
                                  required: false,
                                })}
                                placeholder="Address line 3 (if applicable)"
                              />
                            </Form.Group>
                            <Form.Group className="mb-4 col-lg-12">
                              <Form.Control
                                type="text"
                                className={`${watchAllFields.city ? "border-brand" : ""
                                  }`}
                                value={watchAllFields.city}
                                {...register("city", {
                                  required: false,
                                })}
                                placeholder="City/Town/Settlement*"
                              />
                            </Form.Group>
                            <Form.Control
                              type="text"
                              className={`${watchAllFields.zipcode ? "border-brand" : "mb-4"
                                }`}
                              onKeyPress={(e) =>
                                HelperService.allowOnlyNumericValue(e)
                              }
                              {...register("zipcode", { required: false })}
                              placeholder="Postal code*"
                            />
                          </>
                        )}
                      </div>
                    )}
                  </>
                </>
              )}

              <Form.Group className="mb-4 col-lg-12">
                <Form.Label>
                  {props.type == 1 ? "1st" : "2nd"} parent's email address
                </Form.Label>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.parent_email ? "border-brand" : ""}`}
                  placeholder=""
                  {...register("parent_email", {
                    required: false,
                    pattern: emailRegex,
                  })}
                />
                {errors.parent_email && (
                  <div className="login-error">
                    <Label
                      title={"Please Enter Valid Email."}
                      modeError={true}
                    />
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Contact phone number (optional)</Form.Label>
                <Form.Check
                  type="radio"
                  id="Home"
                  label="Home"
                  value={"HOME"}
                  onClick={() => {
                    watchAllFields.is_parent_phone_number != "HOME" &&
                      setValue("parent_phone_number", "");
                  }}
                  {...register("is_parent_phone_number", { required: false })}
                />
                {watchAllFields.is_parent_phone_number == "HOME" && (
                  <Form.Group className="mb-3 mt-2 ms-3">
                    {/* <Form.Label> Preferred phone number</Form.Label> */}
                    <Controller
                      control={control}
                      name="parent_phone_number"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <div>
                          <PhoneNumberInput
                            selected={watchAllFields.parent_phone_code}
                            value={watchAllFields.parent_phone_number}
                            onChange={(e: any) => {
                              field.onChange(e);
                            }}
                            onChangeCode={(e: any) =>
                              setValue("parent_phone_code", e)
                            }
                          />
                        </div>
                      )}
                    />
                  </Form.Group>
                )}
                <Form.Check
                  type="radio"
                  id="Mobile"
                  label="Mobile"
                  value={"MOBILE"}
                  onClick={() => {
                    watchAllFields.is_parent_phone_number != "MOBILE" &&
                      setValue("parent_phone_number", "");
                  }}
                  {...register("is_parent_phone_number", { required: false })}
                />
                {watchAllFields.is_parent_phone_number == "MOBILE" && (
                  <Form.Group className="mb-3 mt-2 ms-3">
                    {/* <Form.Label> Preferred phone number</Form.Label> */}
                    <Controller
                      control={control}
                      name="parent_phone_number"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <div>
                          <PhoneNumberInput
                            selected={watchAllFields.parent_phone_code}
                            value={watchAllFields.parent_phone_number}
                            onChange={(e: any) => {
                              field.onChange(e);
                            }}
                            onChangeCode={(e: any) =>
                              setValue("parent_phone_code", e)
                            }
                          />
                        </div>
                      )}
                    />
                  </Form.Group>
                )}
                <Form.Check
                  type="radio"
                  id="Work"
                  label="Work"
                  value={"WORK"}
                  onClick={() => {
                    watchAllFields.is_parent_phone_number != "WORK" &&
                      setValue("parent_phone_number", "");
                  }}
                  {...register("is_parent_phone_number", { required: false })}
                />
                {watchAllFields.is_parent_phone_number == "WORK" && (
                  <Form.Group className="mb-3 mt-2 ms-3">
                    <Controller
                      control={control}
                      name="parent_phone_number"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <div>
                          <PhoneNumberInput
                            selected={watchAllFields.parent_phone_code}
                            value={watchAllFields.parent_phone_number}
                            onChange={(e: any) => {
                              field.onChange(e);
                            }}
                            onChangeCode={(e: any) =>
                              setValue("parent_phone_code", e)
                            }
                          />
                        </div>
                      )}
                    />
                  </Form.Group>
                )}
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>
                  What is the profession of your{" "}
                  {props.type == 1 ? "1st" : "2nd"} parent? (What do they do for
                  a living? If they are retired or have passed away, what did
                  they do?)
                </Form.Label>
                <Controller
                  control={control}
                  name="parent_occupation_id"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <OpenSelect
                      sakey="parent_occupation_id"
                      isSearchable={true}
                      options={occupationOption}
                      selected={watchAllFields.parent_occupation_id}
                      onChange={(e: any) => {
                        field.onChange(e.id);
                      }}
                      placeholder="Choose an option"
                    />
                  )}
                />
              </Form.Group>

              {watchAllFields.parent_occupation_id &&
                watchAllFields.parent_occupation_id == 44 && (
                  <Form.Group className="mb-4 col-lg-12">
                    <Form.Label>Other</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${watchAllFields.parent_other_occupation ? "border-brand" : ""}`}
                      placeholder="Enter occupation"
                      {...register("parent_other_occupation", {
                        required: false,
                      })}
                    />
                  </Form.Group>
                )}

              <Form.Group className="mb-4">
                <Form.Label>Employment status</Form.Label>
                <Controller
                  control={control}
                  name="parent_employment_status"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <OpenSelect
                      sakey="parent_employment_status"
                      isSearchable={true}
                      options={parentEmployment}
                      selected={watchAllFields.parent_employment_status}
                      onChange={(e: any) => {
                        field.onChange(e.id);
                      }}
                      placeholder="Choose an option"
                    />
                  )}
                />
              </Form.Group>

              {watchAllFields.parent_employment_status &&
                (watchAllFields.parent_employment_status == "EMPLOYED" ||
                  watchAllFields.parent_employment_status == "RETIRED") && (
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Has Parent {props.type} ever worked at a college or
                      university?<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Check
                      type="radio"
                      id="Currentlyemployed"
                      label="Currently employed at a college/university"
                      value="Currently employed at a college/university"
                      {...register("parent_currently_employed", {
                        required: false,
                      })}
                    />
                    <Form.Check
                      type="radio"
                      id="Retired"
                      label="Retired from a college/university"
                      value="Retired from a college/university"
                      {...register("parent_currently_employed", {
                        required: false,
                      })}
                    />
                    <Form.Check
                      type="radio"
                      id="NotEmployed"
                      label="Not employed at a college/university"
                      value="Not employed at a college/university"
                      {...register("parent_currently_employed", {
                        required: false,
                      })}
                    />
                  </Form.Group>
                )}

              {watchAllFields.parent_employment_status &&
                watchAllFields.parent_employment_status == "EMPLOYED" && (
                  <Form.Group className="mb-4">
                    <Form.Label>
                      {props.type == 1 ? "1st" : "2nd"} parent's employer
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={`${watchAllFields.name_current_employer ? "border-brand" : ""}`}
                      value={watchAllFields.name_current_employer}
                      {...register("name_current_employer", {
                        required: false,
                      })}
                    />
                  </Form.Group>
                )}

              <Form.Group className="mb-4">
                <Form.Label>
                  What is the highest education they've received?
                  <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="parent_highest_education"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <OpenSelect
                      sakey="parent_highest_education"
                      isSearchable={true}
                      options={parentHighSchool}
                      selected={watchAllFields.parent_highest_education}
                      onChange={(e: any) => {
                        field.onChange(e.id);
                      }}
                      placeholder="Choose an option"
                    />
                  )}
                />
              </Form.Group>

              {watchAllFields.parent_highest_education &&
                watchAllFields.parent_highest_education !== "NONE" && (
                  <>
                    <Form.Group className="mb-4">
                      <Form.Label>
                        Total number of institutions attended{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Check
                        type="radio"
                        id="one"
                        label="1"
                        value="1"
                        {...register("institution_attended", {
                          required: false,
                        })}
                        checked={
                          watchAllFields.institution_attended == "1"
                            ? true
                            : false
                        }
                        onClick={() => resetOtherFields(1)}
                      />
                      <Form.Check
                        type="radio"
                        id="two"
                        label="2"
                        value="2"
                        {...register("institution_attended", {
                          required: false,
                        })}
                        checked={
                          watchAllFields.institution_attended == "2"
                            ? true
                            : false
                        }
                        onClick={() => resetOtherFields(2)}
                      />
                      <Form.Check
                        type="radio"
                        id="three"
                        label="3"
                        value="3"
                        {...register("institution_attended", {
                          required: false,
                        })}
                        checked={
                          watchAllFields.institution_attended == "3"
                            ? true
                            : false
                        }
                        onClick={() => resetOtherFields(3)}
                      />
                      <Form.Check
                        type="radio"
                        id="four"
                        label="4"
                        value="4"
                        {...register("institution_attended", {
                          required: false,
                        })}
                        checked={
                          watchAllFields.institution_attended == "4"
                            ? true
                            : false
                        }
                        onClick={() => resetOtherFields(4)}
                      />
                      <Form.Check
                        type="radio"
                        id="five"
                        label="5"
                        value="5"
                        {...register("institution_attended", {
                          required: false,
                        })}
                        checked={
                          watchAllFields.institution_attended == "5"
                            ? true
                            : false
                        }
                        onClick={() => resetOtherFields(5)}
                      />
                    </Form.Group>

                    {array.map((_, index) => {
                      if (_ <= watchAllFields.institution_attended) {
                        return (
                          <div>
                            <Form.Group className="mb-4 sm-arrow">
                              <Form.Label>College {_} name <span className="text-danger">*</span></Form.Label>
                              <Controller
                                control={control}
                                name={`parent_college_id${_}`}
                                rules={{ required: false }}
                                render={({ field }: any) => (
                                  <div className="form-style  mb-3">
                                    <AsyncUnivarsityPaginationSelect
                                      isParent={true}
                                      placeholder="Choose an option"
                                      sakey={`parent_college_id${_}`}
                                      selected={watchAllFields[`parent_college_id${_}`]}
                                      type="UNIVERSITY"
                                      onChange={(e: any) => {
                                        field.onChange(e); onChangeSchool(e, _);
                                      }}
                                      isCustom={true}
                                    />
                                  </div>
                                )}
                              />
                            </Form.Group>

                            <Controller
                              control={control}
                              name={`school_address${_}`}
                              render={({ field }: any) => <></>}
                              rules={{ required: true }}
                            />
                            {watchAllFields[`parent_college_id${_}`] && watchAllFields[`parent_college_id${_}`].type == "CUSTOM" ? (
                              <Form.Group className="mb-4">
                                <InputGroup>
                                  <Form.Control
                                    className={`${watchAllFields[`school_address${_}`] ? "border-brand border-end-0 rounded-end-0" : "border-end-0 rounded-end-0"}`}
                                    type={"text"} placeholder="School address" disabled value={watchAllFields[`school_address${_}`]} />
                                  <InputGroup.Text
                                    id="basic-addon2"
                                    onClick={() => { onEditSchoolAddress(_); }}
                                    className={`${watchAllFields[`school_address${_}`] ? "border-brand cursor-pointer bg-transparent" : "cursor-pointer bg-transparent"}`}
                                  >
                                    <img className="cursor-pointer" src={editIcon} alt="icon" />
                                  </InputGroup.Text>
                                </InputGroup>
                                {errors.school_address && !watchAllFields[`school_address${_}`] && (
                                  <div className="login-error">
                                    <Label title={"Please enter school address"} modeError={true} />
                                  </div>
                                )}
                              </Form.Group>
                            ) : (
                              <Form.Group className="mb-4">
                                <Form.Control
                                  type="text"
                                  className={`${watchAllFields[`school_address${_}`] ? "border-brand" : ""}`}
                                  placeholder="School address"
                                  disabled
                                  value={watchAllFields[`school_address${_}`]}
                                />
                              </Form.Group>
                            )}

                            <Form.Group className="mb-4">
                              <Form.Label>
                                What is the highest degree they received?
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Controller
                                control={control}
                                name={`parent_highest_degree${_}`}
                                rules={{ required: false }}
                                render={({ field }: any) => (
                                  <OpenSelect
                                    sakey={`parent_highest_degree${_}`}
                                    isSearchable={true}
                                    options={parentDegreeType}
                                    selected={watchAllFields[`parent_highest_degree${_}`]}
                                    onChange={(e: any) => { field.onChange(e.id); }}
                                    placeholder="Choose an option"
                                  />
                                )}
                              />
                            </Form.Group>
                          </div>
                        );
                      }
                    })}
                  </>
                )}
            </Form>
            <div className="text-center">
              <Button
                type="submit"
                className="btn btn-brand-1 w-100"
                id={props.type == 1 ? "parent1_save_btn" : "parent2_save_btn"}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>

      {openSchoolAddress && <AddSchoolAddress show={openSchoolAddress} isClose={onClose} schoolName={editAddress && editAddress?.school_college_id && editAddress?.school_college_id.label} data={editAddress} />}
    </>
  );
};
export default Parent;
