import { Card, Row, Col, Tab, Nav, Dropdown } from "react-bootstrap"
import { FaCheck } from "react-icons/fa6";
import iconApplication from "../../../assets/images/icon-list-color.svg"
import { useEffect, useState } from "react";
import WebService from "../../../Services/WebService";
import { useLocation, useNavigate } from "react-router-dom";
import '../my-details.scss';
import menuIcon from "../../../assets/images/menu-icon.svg";
import PersonalEssay from "./PersonalEssay";
import AdditionalInfo from "./AdditionalInfo";

const hashData = ["personalEssay", "additionalInfo"];

const MyDetailWritingSamples = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<string>('personalEssay');
    const [pageStatus, setPageStatus] = useState<any>({ aboutWritingSample: 0, isCompletePersonalEssay: false, isCompleteAdditionalInformation: false });
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [confirmTabChange, setConfirmTabChange] = useState<string>('');

    useEffect(() => {
        getPageStatus();
        if (location.hash) {
            hashData.map((item: any) => {
                if (item == location.hash.replaceAll("#", "")) {
                    setActiveTab(item);
                }
            })
        }
        var PageHeading: any = document.getElementById("page_title");
        PageHeading.textContent = "Writing Samples";
        var BackArrow: any = document.getElementById("back_nav");
        BackArrow.classList.add("d-block");
        BackArrow.onclick = (() => { window.history.back() });
        return () => {
            PageHeading.textContent = "";
            BackArrow.classList.remove("d-block");
            BackArrow.onclick = (() => { window.history.back() });
        }
        // eslint-disable-next-line
    }, []);

    const getPageStatus = () => {
        WebService.getAPI({ action: "get/personal-detail?type=WRITING_SAMPLES" })
            .then((res: any) => {
                if (res.result) {
                    setPageStatus(res.result);
                }
            })
            .catch((e: any) => { });
    };

    const onChangeTab = (tab: string) => {
        if (isFormDirty) {
            setConfirmTabChange(tab);
        } else {
            setActiveTab(tab);
            navigate(`#${tab}`, { replace: true });
        }
    };

    const updateFormStatus = (e: boolean, tab: string) => {
        if (tab == activeTab) {
            setIsFormDirty(e);
        }
    };

    const complitionStatus = (status: any) => {
        if (!status) return <></>;
        if (status == "COMPLETED") {
            return <FaCheck size={16} className="text-brand" />
        } else if (status == "INPROGRESS") {
            return <span className="font-12 text-secondary font-light">In progress</span>
        } else if (status == "NOT_STARTED") {
            return <span className="font-12 text-secondary font-light">Not started</span>
        }
    }


    return (
        <>
            <div className="page-my-details page-container">
                <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                    <Row className="gx-0">
                        <Col className="border-end border-light">
                            <Card className="card-shadow form-style-2 rounded-end-0 card-padding h-100 about-card-min-h mo-no-card-style">
                                <Tab.Content>
                                    <Tab.Pane eventKey="personalEssay">
                                        {activeTab == "personalEssay" && <PersonalEssay tab={activeTab} isUpdate={() => getPageStatus()} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="additionalInfo">
                                        {activeTab == "additionalInfo" && <AdditionalInfo tab={activeTab} isUpdate={() => getPageStatus()} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Card>
                        </Col>
                        <Col lg={4} className="about-tab-col">
                            <div className="d-lg-block d-none d-flex h-100">
                                <Card className="card-shadow bg-light rounded-start-0 h-100 rounded-xs-0" style={{ backgroundColor: "#F9F9F9" }}>
                                    <Nav variant="pills" className="flex-column tab-style-2">
                                        <Nav.Item onClick={() => { activeTab != "personalEssay" && onChangeTab("personalEssay"); }}>
                                            <Nav.Link eventKey="personalEssay" className=" rounded-top-4 rounded-start-0 rounded-xs-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span><img src={iconApplication} className="icon" alt="" /> Personal Essay</span>
                                                    {complitionStatus(pageStatus.isCompletePersonalEssay)}
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={() => { activeTab != "additionalInfo" && onChangeTab("additionalInfo"); }}>
                                            <Nav.Link eventKey="additionalInfo" className=" rounded-top-4 rounded-start-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span><img src={iconApplication} className="icon" alt="" /> Additional Information</span>
                                                    {complitionStatus(pageStatus.isCompleteAdditionalInformation)}
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Card>
                            </div>
                            <Dropdown className="d-lg-none d-block mob-tab-menu">
                                <Dropdown.Toggle className="btn btn-tabs-nav"><img src={menuIcon} width={18} alt="" /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Card className="card-shadow bg-light rounded-start-0 h-100 rounded-xs-0" style={{ backgroundColor: "#F9F9F9" }}>
                                        <Nav variant="pills" className="flex-column tab-style-2">
                                            <Nav.Item onClick={() => { activeTab != "personalEssay" && onChangeTab("personalEssay"); }}>
                                                <Nav.Link eventKey="personalEssay" className=" rounded-top-4 rounded-start-0 rounded-xs-0">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span><img src={iconApplication} className="icon" alt="" /> Personal Essay</span>
                                                        {complitionStatus(pageStatus.isCompletePersonalEssay)}
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={() => { activeTab != "additionalInfo" && onChangeTab("additionalInfo"); }}>
                                                <Nav.Link eventKey="additionalInfo" className=" rounded-top-4 rounded-start-0">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span><img src={iconApplication} className="icon" alt="" /> Additional Information</span>
                                                        {complitionStatus(pageStatus.isCompleteAdditionalInformation)}
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}
export default MyDetailWritingSamples;
