import { Button, Modal } from "react-bootstrap";
import WebService from "../../../Services/WebService";
import { userInfoInterface } from "../../../OpenInterfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../config/Store";

interface propsData {
    show: boolean;
    isClose: Function;
}

export const LogoutModal = ({ show, isClose }: propsData) => {
    const user_info: userInfoInterface = useSelector<RootState, userInfoInterface>((state: any) => state.commonData.user_info);

    const onLogout = async () => {
        let token_id: any = localStorage.getItem("token")?.split("|")
        WebService.logoutHandler(user_info.id, token_id[0]);
    };

    return (
        <Modal show={show} centered className="logout-modal">
            <Modal.Body className=" " style={{padding: "30px"}}>
                <div>
                    <p className="font-20 font-medium pb-3">Log out from Open?</p>
                    <div className="d-flex gap-3 justify-content-center mt-4">
                        <Button className="btn-brand-1 w-100" onClick={() => { onLogout() }} style={{height:"44px"}}>Yes, log out</Button>
                        <Button className="btn-outline-brand w-100" onClick={() => isClose()} style={{height:"44px", maxWidth: '163px'}}> Cancel</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LogoutModal;
