import { Card, Row, Col, Tab, Nav, Dropdown } from "react-bootstrap"
import { FaCheck } from "react-icons/fa6";
import iconApplication from "../../../assets/images/icon-list-color.svg"
import { useEffect, useState } from "react";
import WebService from "../../../Services/WebService";
import { useNavigate } from "react-router-dom";
import '../my-details.scss'
import { MdCircle } from "react-icons/md";
import SportIcon from "../../../assets/images/icon-sport.svg";
import menuIcon from "../../../assets/images/menu-icon.svg"


const MyDetailExtraCurricular = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<string>('testsTaken');
    const [pageStatus, setPageStatus] = useState<any>({
        "isCompleteExtracurricularActivities": false
    });
    const [userActivityList, setUserActivityList] = useState<any[]>([]);

    useEffect(() => {
        getPageStatus();
        getUserActivityList();
        var PageHeading: any = document.getElementById("page_title");
        PageHeading.textContent = "Extracurriculars & Activities";
        var BackArrow: any = document.getElementById("back_nav");
        BackArrow.classList.add("d-block");
        BackArrow.onclick = (() => { window.history.back() });
        return () => {
            PageHeading.textContent = "";
            BackArrow.classList.remove("d-block");
            BackArrow.onclick = (() => { window.history.back() });
        }
        // eslint-disable-next-line
    }, []);

    const getPageStatus = () => {
        WebService.getAPI({ action: "get/personal-detail?type=EXTRACURRICULAR_ACTIVITIES" })
            .then((res: any) => {
                if (res.result) {
                    setPageStatus(res.result);
                }
            })
            .catch((e: any) => { });
    };

    const getUserActivityList = () => {
        WebService.getAPI({
            action: "user-activities/list",
        })
            .then((res: any) => {
                setUserActivityList(res.list);
            })
            .catch(() => { });
    };

    const getLevels = (data: any[]) => {
        let str: string = "";
        data.map((ite: any, i: number) => {
            if (ite == "9") {
                str += ite + "th" + (i == data.length - 1 ? " grade " : i == data.length - 2 ? (data[i + 1] == "Post graduate" ? " grade " : "") + " and " : ", ")
            } else if (ite == "10") {
                str += ite + "th" + (i == data.length - 1 ? " grade" : i == data.length - 2 ? " and " : ", ")
            } else if (ite == "11") {
                str += ite + "th" + (i == data.length - 2 ? " and " : i == data.length - 1 ? " grade" : ", ")
            } else if (ite == "12") {
                str += ite + "th" + (i == data.length - 2 ? " grade and " : i == data.length - 1 ? " grade " : ", ")
            } else if (ite == "Post graduate") {
                str += " " + ite
            }
        });
        return str;
    };

    const complitionStatus = (status: any) => {
        if (!status) return <></>;
        if (status == "COMPLETED") {
            return <FaCheck size={16} className="text-brand" />
        } else if (status == "INPROGRESS") {
            return <span className="font-12 text-secondary font-light">In progress</span>
        } else if (status == "NOT_STARTED") {
            return <span className="font-12 text-secondary font-light">Not started</span>
        }
    }


    return (
        <>
            <div className="page-my-details page-container detail-card-min-h mo-no-card-style  card-padding h-100">
                <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                    <Row className="gx-0">
                        <Col className="border-end border-light">
                            <Card className="card-shadow form-style-2 rounded-end-0 card-padding h-100 about-card-min-h mo-no-card-style">
                                <Tab.Content>
                                    <Tab.Pane eventKey="testsTaken">
                                        <Row>
                                            <Col style={{ maxWidth: "624px" }}>
                                                <h4 className="font-medium font-20 mb-3 pb-1">Extracurriculars & Activities</h4>
                                                <p className="text-secondary font-14">If you'd like to add or edit an extracurricular or activity, please do so from the section in your <span className="text-brand cursor-pointer text-decoration-underline" onClick={() => navigate("/my-profile")}>profile</span></p>
                                                {userActivityList &&
                                                    userActivityList.length > 0 &&
                                                    userActivityList.map((item: any, index: number) => (
                                                        <Card className="border p-4 rounded-4 mb-4 border-opacity-50">
                                                            <div className="d-flex gap-3 mb-4" key={index}>
                                                                {(item.category.image && item.category.image !== null) &&
                                                                    <div>
                                                                        <div className=" bg-brand-light rounded-circle p-2 d-flex">
                                                                            <img
                                                                                src={item.category.image}
                                                                                width={28}
                                                                                height={28}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div>
                                                                    <p className="mb-0">{item.activities.name}</p>
                                                                    <p className="font-14 text-secondary mt-2">
                                                                        {item.category.name}{" "}
                                                                        <MdCircle
                                                                            size={7}
                                                                            className=" opacity-50 mx-1"
                                                                        />{" "}
                                                                        {item.achivements.level_id}{" "}{item.achivements.level}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <Row className="">
                                                                <Col lg={6} xs={6} className="font-14 mb-3 font-light text-nowrap font-xs-12">Participation grade levels:</Col>
                                                                <Col lg={6} xs={6} className="font-12 text-secondary text-end mb-2 font-light font-xs-12">{item.grade_level && item.grade_level.length > 0 && getLevels(item.grade_level)}</Col>
                                                                <Col lg={6} xs={6} className="font-14 font-light text-nowrap font-xs-12 mb-3">Timing of participation:</Col>
                                                                <Col lg={6} xs={6} className="font-12 text-secondary text-end font-light mb-2">{item.timing_participation}</Col>
                                                                <Col lg={6} xs={6} className="font-14 mb-3 font-light text-nowrap font-xs-12">Hours spent per week:</Col>
                                                                <Col lg={6} xs={6} className="font-12 text-secondary text-end font-light">{item.hours_per_week}</Col>
                                                                <Col lg={6} xs={6} className="font-14 font-light font-xs-12 mb-3">Weeks spent per year:</Col>
                                                                <Col lg={6} xs={6} className="font-12 text-secondary text-end font-light">{item.weeks_per_year}</Col>
                                                            </Row>
                                                        </Card>
                                                    ))}
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Card>
                        </Col>
                        <Col lg={4} className="about-tab-col">
                            <div className="d-lg-block d-none d-flex h-100">
                                <Card className="card-shadow bg-light rounded-start-0 h-100 d-flex" style={{ backgroundColor: "#F9F9F9" }}>
                                    <Nav variant="pills" className="flex-column tab-style-2">
                                        <Nav.Item onClick={() => { activeTab != "testsTaken" && setActiveTab("testsTaken"); }}>
                                            <Nav.Link eventKey="testsTaken" className=" rounded-top-4 rounded-xs-0 rounded-start-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span><img src={iconApplication} className="icon" alt="" />Extracurriculars & Activities</span>
                                                    {complitionStatus(pageStatus.isCompleteExtracurricularActivities)}
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                </Card>
                            </div>


                            <Dropdown drop="up" className="d-lg-none d-block mob-tab-menu ">
                                <Dropdown.Toggle className="btn btn-tabs-nav "><img src={menuIcon} width={18} alt="" /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Card className="card-shadow bg-light rounded-start-0 h-100" style={{ backgroundColor: "#F9F9F9" }}>
                                        <Nav variant="pills" className="flex-column tab-style-2">
                                            <Nav.Item onClick={() => { activeTab != "testsTaken" && setActiveTab("testsTaken"); }}>
                                                <Nav.Link eventKey="testsTaken">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span><img src={iconApplication} className="icon" alt="" />Extracurriculars & Activities</span>
                                                        {complitionStatus(pageStatus.isCompleteExtracurricularActivities)}
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>

                                    </Card>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}
export default MyDetailExtraCurricular;
