import { Card, Row, Col, Tab, Nav, Dropdown } from "react-bootstrap"
import { FaCheck } from "react-icons/fa6";
import iconApplication from "../../../assets/images/icon-list-color.svg"
import { useEffect, useState } from "react";
import TabTestsTaken from "../../../components/my-details/TabTestsTaken";
import WebService from "../../../Services/WebService";
import { useLocation, useNavigate } from "react-router-dom";
import ACTTest from "./ACTTest";
import menuIcon from "../../../assets/images/menu-icon.svg"
import SATTest from "./SATTest";
import IBSubjectTest from "./IBSubjectTest";
import CambridgeTest from "./CambridgeTest";
import ToeflIBTTest from "./ToeflIBTTest";
import DuelingoTest from "./DuelingoTest";
import IELTSTest from "./IELTSTest";
import PTEAcadmicTest from "./PTEAcadmicTest";
import APSubjectTest from "./APSubjectTest";
import PSATTest from "./PSATTest";
import '../my-details.scss'


//Page Title
const hashData = ["testsTaken", "ACTTests", "SATTests", "APSubjectTests", "IBSubjectTests", "Cambridge", "TOEFLiBT", "PTEAcademicTest", "IELTS", "DuolingoEnglishTest", "PSATTests"];

const MyDetailTestTaken = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<string>('testsTaken');
    const [testDatail, setTestDetail] = useState<any>({})
    const [pageStatus, setPageStatus] = useState<any>({ "aboutTest": 0, "isCompleteTestTaken": false, "isCompleteACT": false, "isCompleteSAT": false, "isCompletePSAT": false, "isCompleteIbSubjectTest": false, "isCompleteCambridgeTest": false, "isCompleteToeflTest": false, "isCompleteIeltsTest": false, "isCompleteDuolingoTest": false, "isCompletePteTest": false, "isCompleteApExam": false });
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [confirmTabChange, setConfirmTabChange] = useState<string>('');

    useEffect(() => {
        getTestDatail();
        getPageStatus();
        if (location.hash) {
            hashData.map((item: any) => {
                if (item == location.hash.replaceAll("#", "")) {
                    setActiveTab(item);
                }
            })
        };
        var PageHeading: any = document.getElementById("page_title");
        PageHeading.textContent = "Standardized Tests";
        var BackArrow: any = document.getElementById("back_nav");
        BackArrow.classList.add("d-block");
        BackArrow.onclick = (() => { window.history.back() });
        return () => {
            PageHeading.textContent = "";
            BackArrow.classList.remove("d-block");
            BackArrow.onclick = (() => { window.history.back() });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (location.hash) {
            testDatail && testDatail?.future_already_completed && testDatail?.future_already_completed.length > 0 && hashData.map((item: any) => {
                if (item == location.hash.replaceAll("#", "")) {
                    setTimeout(() => {
                        setActiveTab(item);
                    }, 1000)
                }
            })
        };
    }, [testDatail])

    const getPageStatus = () => {
        WebService.getAPI({ action: "get/personal-detail?type=STANDARDIZED_TESTS" })
            .then((res: any) => {
                if (res.result) {
                    setPageStatus(res.result);
                }
            })
            .catch((e: any) => { });
    };

    const getTestDatail = () => {
        WebService.getAPI({ action: "get/test/detail" })
            .then((res: any) => {
                if (res.result) {
                    setTestDetail(res.result);
                }
            })
            .catch((e: any) => { });
    };

    const onChangeTab = (tab: string) => {
        if (isFormDirty) {
            setConfirmTabChange(tab);
        } else {
            setActiveTab(tab);
            navigate(`#${tab}`, { replace: true });
        }
    };

    const updateFormStatus = (e: boolean, tab: string) => {
        if (tab == activeTab) {
            setIsFormDirty(e);
        }
    };

    const complitionStatus = (status: any) => {
        if (!status) return <></>;
        if (status == "COMPLETED") {
            return <FaCheck size={16} className="text-brand" />
        } else if (status == "INPROGRESS") {
            return <span className="font-12 text-secondary font-light">In progress</span>
        } else if (status == "NOT_STARTED") {
            return <span className="font-12 text-secondary font-light">Not started</span>
        }
    };

    return (
        <>
            <div className="page-my-details page-container detail-card-min-h mo-no-card-style  card-padding h-100">
                <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                    <Row className="gx-0">
                        <Col className="border-end border-light">
                            <Card className="card-shadow form-style-2 rounded-end-0 card-padding h-100 about-card-min-h mo-no-card-style">

                                <Tab.Content>
                                    <Tab.Pane eventKey="testsTaken">
                                        {activeTab == "testsTaken" && <TabTestsTaken tab={activeTab} data={testDatail} isUpdate={() => { getTestDatail(); getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    {
                                        testDatail && testDatail?.future_already_completed && testDatail?.future_already_completed.length > 0 &&
                                        testDatail?.future_already_completed.map((item: any) => {
                                            return (
                                                <>
                                                    {item == "ACT_TEST" &&
                                                        <Tab.Pane eventKey="ACTTests">
                                                            {activeTab == "ACTTests" && <ACTTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                    {item == "SAT_TEST" &&
                                                        <Tab.Pane eventKey="SATTests">
                                                            {activeTab == "SATTests" && <SATTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                    {item == "PSAT_TEST" &&
                                                        <Tab.Pane eventKey="PSATTests">
                                                            {activeTab == "PSATTests" && <PSATTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                    {item == "IB_SUBJECT_TEST" &&
                                                        <Tab.Pane eventKey="IBSubjectTests">
                                                            {activeTab == "IBSubjectTests" && <IBSubjectTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                    {item == "CAMBRIDGE_TEST" &&
                                                        <Tab.Pane eventKey="Cambridge">
                                                            {activeTab == "Cambridge" && <CambridgeTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                    {item == "TOEFL_IBT_TEST" &&
                                                        <Tab.Pane eventKey="TOEFLiBT">
                                                            {activeTab == "TOEFLiBT" && <ToeflIBTTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                    {item == "PTE_ACADEMIC_TEST" &&
                                                        <Tab.Pane eventKey="PTEAcademicTest">
                                                            {activeTab == "PTEAcademicTest" && <PTEAcadmicTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                    {item == "IELTS_TEST" &&
                                                        <Tab.Pane eventKey="IELTS">
                                                            {activeTab == "IELTS" && <IELTSTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                    {item == "DUOLINGO_TEST" &&
                                                        <Tab.Pane eventKey="DuolingoEnglishTest">
                                                            {activeTab == "DuolingoEnglishTest" && <DuelingoTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                    {item == "AP_SUBJECT_TEST" &&
                                                        <Tab.Pane eventKey="APSubjectTests">
                                                            {activeTab == "APSubjectTests" && <APSubjectTest activeTab={activeTab} isUpdate={() => { getPageStatus() }} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                                        </Tab.Pane>
                                                    }
                                                </>
                                            )
                                        })

                                    }

                                </Tab.Content>

                            </Card>
                        </Col>
                        <Col lg={4} className="about-tab-col">
                            <Card className="card-shadow bg-light rounded-start-0 h-100 d-none d-lg-block " style={{ backgroundColor: "#F9F9F9" }}>
                                <Nav variant="pills" className="flex-column tab-style-2">
                                    <Nav.Item onClick={() => { activeTab != "testsTaken" && onChangeTab("testsTaken"); }}>
                                        <Nav.Link eventKey="testsTaken" className=" rounded-top-4 rounded-xs-0 rounded-start-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span><img src={iconApplication} className="icon" alt="" />Tests taken</span>
                                                {complitionStatus(pageStatus.isCompleteTestTaken)}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {
                                        testDatail && testDatail?.future_already_completed && testDatail?.future_already_completed.length > 0 &&
                                        testDatail?.future_already_completed.map((item: any) => {
                                            return (
                                                <>
                                                    {item == "ACT_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "ACTTests" && onChangeTab("ACTTests"); }}>
                                                            <Nav.Link eventKey="ACTTests">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />ACT Tests</span>
                                                                    {complitionStatus(pageStatus.isCompleteACT)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    {item == "SAT_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "SATTests" && onChangeTab("SATTests"); }}>
                                                            <Nav.Link eventKey="SATTests">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />SAT Tests</span>
                                                                    {complitionStatus(pageStatus.isCompleteSAT)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    {item == "PSAT_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "PSATTests" && onChangeTab("PSATTests"); }}>
                                                            <Nav.Link eventKey="PSATTests">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />PSAT</span>
                                                                    {complitionStatus(pageStatus.isCompletePSAT)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    {item == "AP_SUBJECT_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "APSubjectTests" && onChangeTab("APSubjectTests"); }}>
                                                            <Nav.Link eventKey="APSubjectTests">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />AP Subject Tests</span>
                                                                    {complitionStatus(pageStatus.isCompleteApExam)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    {item == "IB_SUBJECT_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "IBSubjectTests" && onChangeTab("IBSubjectTests"); }}>
                                                            <Nav.Link eventKey="IBSubjectTests">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />IB Subject Tests</span>
                                                                    {complitionStatus(pageStatus.isCompleteIbSubjectTest)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    {item == "CAMBRIDGE_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "Cambridge" && onChangeTab("Cambridge"); }}>
                                                            <Nav.Link eventKey="Cambridge">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />Cambridge</span>
                                                                    {complitionStatus(pageStatus.isCompleteCambridgeTest)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    {item == "TOEFL_IBT_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "TOEFLiBT" && onChangeTab("TOEFLiBT"); }}>
                                                            <Nav.Link eventKey="TOEFLiBT">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />TOEFL iBT</span>
                                                                    {complitionStatus(pageStatus.isCompleteToeflTest)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    {item == "PTE_ACADEMIC_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "PTEAcademicTest" && onChangeTab("PTEAcademicTest"); }}>
                                                            <Nav.Link eventKey="PTEAcademicTest">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />PTE Academic Test</span>
                                                                    {complitionStatus(pageStatus.isCompletePteTest)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    {item == "IELTS_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "IELTS" && onChangeTab("IELTS"); }}>
                                                            <Nav.Link eventKey="IELTS">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />IELTS</span>
                                                                    {complitionStatus(pageStatus.isCompleteIeltsTest)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    {item == "DUOLINGO_TEST" &&
                                                        <Nav.Item onClick={() => { activeTab != "DuolingoEnglishTest" && onChangeTab("DuolingoEnglishTest"); }}>
                                                            <Nav.Link eventKey="DuolingoEnglishTest">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span><img src={iconApplication} className="icon" alt="" />Duolingo English Test</span>
                                                                    {complitionStatus(pageStatus.isCompleteDuolingoTest)}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </Nav>

                            </Card>


                            <Dropdown className="d-lg-none d-block mob-tab-menu">
                                <Dropdown.Toggle className="btn btn-tabs-nav"><img src={menuIcon} width={18} alt="" /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Card className="card-shadow bg-light rounded-start-0 h-100" style={{ backgroundColor: "#F9F9F9" }}>
                                        <Nav variant="pills" className="flex-column tab-style-2">
                                            <Nav.Item onClick={() => { activeTab != "testsTaken" && setActiveTab("testsTaken"); navigate("#testsTaken", { replace: true }) }}>
                                                <Nav.Link eventKey="testsTaken">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span><img src={iconApplication} className="icon" alt="" />Tests taken</span>
                                                        {complitionStatus(pageStatus.isCompleteTestTaken)}
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                            {
                                                testDatail && testDatail?.future_already_completed && testDatail?.future_already_completed.length > 0 &&
                                                testDatail?.future_already_completed.map((item: any) => {
                                                    return (
                                                        <>
                                                            {item == "ACT_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "ACTTests" && onChangeTab("ACTTests"); }}>
                                                                    <Nav.Link eventKey="ACTTests">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />ACT Tests</span>
                                                                            {complitionStatus(pageStatus.isCompleteACT)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                            {item == "SAT_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "SATTests" && onChangeTab("SATTests"); }}>
                                                                    <Nav.Link eventKey="SATTests">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />SAT Tests</span>
                                                                            {complitionStatus(pageStatus.isCompleteSAT)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                            {item == "PSAT_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "PSATTests" && onChangeTab("PSATTests"); }}>
                                                                    <Nav.Link eventKey="PSATTests">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />PSAT</span>
                                                                            {complitionStatus(pageStatus.isCompletePSAT)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                            {item == "AP_SUBJECT_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "APSubjectTests" && onChangeTab("APSubjectTests"); }}>
                                                                    <Nav.Link eventKey="APSubjectTests">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />AP Subject Tests</span>
                                                                            {complitionStatus(pageStatus.isCompleteApExam)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                            {item == "IB_SUBJECT_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "IBSubjectTests" && onChangeTab("IBSubjectTests"); }}>
                                                                    <Nav.Link eventKey="IBSubjectTests">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />IB Subject Tests</span>
                                                                            {complitionStatus(pageStatus.isCompleteIbSubjectTest)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                            {item == "CAMBRIDGE_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "Cambridge" && onChangeTab("Cambridge"); }}>
                                                                    <Nav.Link eventKey="Cambridge">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />Cambridge</span>
                                                                            {complitionStatus(pageStatus.isCompleteCambridgeTest)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                            {item == "TOEFL_IBT_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "TOEFLiBT" && onChangeTab("TOEFLiBT"); }}>
                                                                    <Nav.Link eventKey="TOEFLiBT">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />TOEFL iBT</span>
                                                                            {complitionStatus(pageStatus.isCompleteToeflTest)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                            {item == "PTE_ACADEMIC_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "PTEAcademicTest" && onChangeTab("PTEAcademicTest"); }}>
                                                                    <Nav.Link eventKey="PTEAcademicTest">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />PTE Academic Test</span>
                                                                            {complitionStatus(pageStatus.isCompletePteTest)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                            {item == "IELTS_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "IELTS" && onChangeTab("IELTS"); }}>
                                                                    <Nav.Link eventKey="IELTS">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />IELTS</span>
                                                                            {complitionStatus(pageStatus.isCompleteIeltsTest)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                            {item == "DUOLINGO_TEST" &&
                                                                <Nav.Item onClick={() => { activeTab != "DuolingoEnglishTest" && onChangeTab("DuolingoEnglishTest"); }}>
                                                                    <Nav.Link eventKey="DuolingoEnglishTest">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span><img src={iconApplication} className="icon" alt="" />Duolingo English Test</span>
                                                                            {complitionStatus(pageStatus.isCompleteDuolingoTest)}
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </Nav>

                                    </Card>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}
export default MyDetailTestTaken;
