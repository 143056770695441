import { useEffect, useState } from "react";
import "./req-tracker.scss";
import {
  Row,
  Col,
  Card,
  ProgressBar,
  Accordion,
  Button,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import universityLogo from "../../assets/images/unveristy-logo.png";
import calendarIconColor from "../../assets/images/calendar-icon-color.svg";
import iconGoodJob from "../../assets/images/icon-good-job.svg";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { PiCompassFill } from "react-icons/pi";
import { RiGraduationCapFill } from "react-icons/ri";
import "./liq-graph.scss";
import RequestSchoolReportModal from "./RequestSchoolReportModal";
import UploadDocumentModal from "./UploadDocumentModal";
import WebService from "../../Services/WebService";
import CardLoader from "../../components/Common/CardLoader";
import { detail_university_interface } from "../../OpenInterfaces";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import DocIcon from "../../assets/images/doc-icon.svg";
import { toast } from "react-toastify";
import HelperService from "../../Services/HelperService";

const ReqUniversityDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [unversityDetail, setUniversityDetail] =
    useState<detail_university_interface>({} as detail_university_interface);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [testDatail, setTestDetail] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [requireDocList, setRequireDocList] = useState<any[]>([]);
  const [pageStatus, setPageStatus] = useState<any>({});
  const [isLoadingDocument, setLoadingDocument] = useState<boolean>(false);
  const [showUpload, setShowUpload] = useState(false);
  const [uploadId, setUploadId] = useState<string>("");
  const [requestType, setRequestType] = useState<string>("");
  const [showReqRepo, setShowReqRepo] = useState(false);
  const [showLastStep, setShowLastStep] = useState<boolean>(false);
  const [showDoneStep, setShowDoneStep] = useState<boolean>(false);
  const [requireDocObj, setRequireDocObj] = useState<any>({});
  const [isHover, setIsHover] = useState<boolean>(false);

  useEffect(() => {
    var BackArrow: any = document.getElementById("back_nav");
    BackArrow.classList.add("d-block");
    BackArrow.onclick = () => {
      window.history.back();
    };
    getUniversityDetail();
    getTestDatail();
    getPageStatus();
    getRequiredDocList();
    return () => {
      const element: any = document.getElementById("page_title");
      element.textContent = "";
      BackArrow.classList.remove("d-block");
      BackArrow.onclick = () => {
        window.history.back();
      };
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requireDocList && requireDocList.length) {
      let count: number = 0;
      let obj: any = {};
      requireDocList.map((item: any) => {
        if (item.documents.length == 0) {
          obj = item;
          count = count + 1;
        }
      });
      setRequireDocObj({ ...obj });
      if (count == 1) {
        setShowLastStep(true);
        setRequireDocObj({ ...obj });
      } else {
        setRequireDocObj({});
        setShowLastStep(false);
      }
      if (count === requireDocList.length) {
        setShowDoneStep(true);
      } else {
        setShowDoneStep(false);
      }
    } else {
      setRequireDocObj({});
      setShowLastStep(false);
      setShowDoneStep(false);
    }
  }, [requireDocList]);

  const getUniversityDetail = () => {
    setIsLoading(true);
    WebService.getAPI({ action: `get/university/detail/${id}` })
      .then((res: any) => {
        setUniversityDetail({ ...res.result });
        const element: any = document.getElementById("page_title");
        if (element) {
          element.textContent = res.result.name;
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
      });
  };

  const handleCloseUpload = () => {
    setUploadId("");
    setShowUpload(false);
  };

  const handleShowUpload = (id: string) => {
    setUploadId(id);
    setShowUpload(true);
  };

  const handleRequestModal = (data: any) => {
    setUploadId(data.id);
    if (data.name_of_document.toLowerCase().includes("recommendation")) {
      setRequestType("LOR");
    } else {
      setRequestType("REPORT");
    }
    setShowReqRepo(true);
  };

  const handleCloseReqRepo = () => {
    setRequestType("");
    setUploadId("");
    setShowReqRepo(false);
  };

  const getPageStatus = () => {
    setLoading(true);
    WebService.getAPI({
      action: `get/user-requirement/details?university_id=${id}`,
    })
      .then((res: any) => {
        if (res.result) {
          setPageStatus(res.result);
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
      });
  };

  const getTestDatail = () => {
    WebService.getAPI({ action: "get/test/detail" })
      .then((res: any) => {
        if (res.result && res.result?.future_already_completed) {
          setTestDetail(res.result?.future_already_completed);
        }
      })
      .catch((e: any) => { });
  };

  const getIncompleteCount = (data: any[]) => {
    let count: number = 0;
    data.map((item: any) => {
      if (item != "COMPLETED") {
        count = count + 1;
      }
    });
    return count;
  };

  const getIncompleteTestCount = () => {
    let count: number = pageStatus.isCompleteTestTaken ? 0 : 1;
    testDatail.map((item: any) => {
      if (item == "ACT_TEST" && pageStatus.isCompleteACT != "COMPLETED") {
        count = count + 1;
      } else if (item == "SAT_TEST" && pageStatus.isCompleteSAT != "COMPLETED") {
        count = count + 1;
      } else if (item == "PSAT_TEST" && pageStatus.isCompletePSAT != "COMPLETED") {
        count = count + 1;
      } else if (item == "AP_SUBJECT_TEST" && pageStatus.isCompleteApExam != "COMPLETED") {
        count = count + 1;
      } else if (item == "IB_SUBJECT_TEST" && pageStatus.isCompleteIbSubjectTest != "COMPLETED") {
        count = count + 1;
      } else if (item == "CAMBRIDGE_TEST" && pageStatus.isCompleteCambridgeTest != "COMPLETED") {
        count = count + 1;
      } else if (item == "TOEFL_IBT_TEST" && pageStatus.isCompleteToeflTest != "COMPLETED") {
        count = count + 1;
      } else if (item == "PTE_ACADEMIC_TEST" && pageStatus.isCompletePteTest != "COMPLETED") {
        count = count + 1;
      } else if (item == "IELTS_TEST" && pageStatus.isCompleteIeltsTest != "COMPLETED") {
        count = count + 1;
      } else if (item == "IELTS_TEST" && pageStatus.isCompleteIeltsTest != "COMPLETED") {
        count = count + 1;
      } else if (item == "DUOLINGO_TEST" && pageStatus.isCompleteDuolingoTest != "COMPLETED") {
        count = count + 1;
      }
    });
    return count;
  };

  const getRequiredDocList = () => {
    setLoadingDocument(true);
    WebService.getAPI({
      action: `get/required-document/list?university_id=${id}`,
    })
      .then((res: any) => {
        setRequireDocList(res.list);
        setLoadingDocument(false);
      })
      .catch((e: any) => {
        setLoadingDocument(false);
      });
  };

  const manageLiquidAnimation = (val: number) => {
    const progress: any = document.getElementsByClassName(`progress1`);
    if (progress && progress.length) {
      let value = val <= 100 && val >= 0 ? val : 50;
      progress[0].style.setProperty(`--progress-value`, value);
      progress[0].dataset.value = value;

      if (value > 50) {
        progress[0].classList.contains("progress--upper-half-value") &&
          progress[0].classList.remove(`progress--upper-half-value`);
      } else {
        progress[0].classList.add(`progress--upper-half-value`);
      }
    }
    return val;
  };

  const removeFile = (id: string) => {
    WebService.deleteAPI({ action: `application-document/delete?uuid=${id}` })
      .then((res: any) => {
        toast.success(res.message);
        getRequiredDocList();
        getPageStatus();
      })
      .catch((e: any) => { });
  };

  return (
    <>
      <div className="page-req-tracker page-container">
        <Card className="card-shadow mb-4 rounded-4 p-4">
          {isLoading ? (
            <CardLoader />
          ) : (
            <Row>
              <Col lg={4} className="mb-lg-0 mb-3">
                <Card
                  className={
                    pageStatus.total_profile_completed < 100
                      ? "bg-light p-4"
                      : "bg-brand-light p-4 border border-brand-2"
                  }
                >
                  <p className="font-medium mb-1">My details</p>
                  <p className=" text-secondary">Information schools need about you.</p>
                  <div className="d-flex gap-2 align-items-center">
                    <ProgressBar
                      now={pageStatus.total_profile_completed}
                      visuallyHidden
                      className="w-100"
                    />
                    <span className="font-medium text-secondary me-3">
                      {HelperService.formateDecimalValue(pageStatus.total_profile_completed)}%
                    </span>
                  </div>
                </Card>
              </Col>
              <Col lg={4} className="mb-lg-0 mb-3">
                <Card
                  className={
                    pageStatus.required_documents < 100
                      ? "bg-light p-4"
                      : "bg-brand-light p-4 border border-brand-2"
                  }
                >
                  <p className="font-medium mb-1">Required documents</p>
                  <p className=" text-secondary">Admissions documents schools need to make decisions. </p>
                  <div className="d-flex gap-2 align-items-center">
                    <ProgressBar
                      now={pageStatus.required_documents}
                      visuallyHidden
                      className="w-100"
                    />
                    <span className="font-medium text-secondary me-3">
                      {HelperService.formateDecimalValue(pageStatus.required_documents)}%
                    </span>
                  </div>
                </Card>
              </Col>
              <Col lg={4}>
                <Card
                  className={
                    pageStatus.wrtingSample < 100
                      ? "bg-light p-4"
                      : "bg-brand-light p-4 border border-brand-2"
                  }
                >
                  <p className="font-medium mb-1">Writing samples</p>
                  <p className=" text-secondary">General and supplemental essays.</p>
                  <div className="d-flex gap-2 align-items-center">
                    <ProgressBar
                      now={pageStatus.wrtingSample}
                      visuallyHidden
                      className="w-100"
                    />
                    <span className="font-medium text-secondary me-3">
                      {HelperService.formateDecimalValue(pageStatus.wrtingSample)}%
                    </span>
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        </Card>
        <Row>
          <Col lg={3}>
            <Card className="card-shadow mb-3 p-2">
              <Card.Body className="p-4">
                {isLoading ? (
                  <CardLoader />
                ) : (
                  <>
                    <img
                      src={universityLogo}
                      className=" roundd-4 w-100 object-fit-contain mb-3"
                      height={250}
                      alt="cover photo"
                    />
                    <div className="d-flex gap-2 mb-2">
                      <span className="text-brand-dark">
                        <PiCompassFill
                          size={20}
                          className=" align-text-bottom"
                        />
                      </span>
                      <span className="text-secondary">
                        Philadelphia, Pennsylvania
                      </span>
                    </div>
                    <div className="d-flex gap-2 align-items-center mb-2">
                      <span className="text-brand-dark">
                        <RiGraduationCapFill
                          size={18}
                          className=" align-text-bottom"
                        />
                      </span>
                      <span className="text-secondary">Private</span>
                    </div>
                    <div className="d-flex gap-2 align-items-center mb-4">
                      <span className="text-brand">
                        <BsStarFill size={18} className=" align-text-bottom" />
                        &nbsp;
                        <BsStarFill size={18} className=" align-text-bottom" />
                        &nbsp;
                        <BsStarFill size={18} className=" align-text-bottom" />
                        &nbsp;
                        <BsStarFill size={18} className=" align-text-bottom" />
                        &nbsp;
                        <BsStarHalf size={18} className=" align-text-bottom" />
                      </span>
                      <span className="text-secondary text-decoration-underline">
                        903 reviews
                      </span>
                    </div>

                    <div className="mt-3">
                      <p className="font-bold font-16 mb-2">
                        Phone and fax numbers
                      </p>
                      <table className=" text-secondary">
                        <tr>
                          <td className="pe-2">Phone: </td>
                          <td> (215) 898-7507</td>
                        </tr>
                        <tr>
                          <td>Fax:</td>
                          <td> (215) 898-9670</td>
                        </tr>
                      </table>
                    </div>
                    <div className="mt-4">
                      <p className="font-bold font-16 mb-2">Email addresses </p>
                      <p className="mb-1 text-secondary">
                        emailemail@email.com
                      </p>
                      <p className="mb-1 text-secondary">
                        university@email.edu
                      </p>
                      <p className="mb-1 text-secondary">
                        schoolemail@email.com
                      </p>
                    </div>
                    <div className="mt-4">
                      <p className="font-bold font-16 mb-2">Mailing address</p>
                      <p className="mb-1 text-secondary">
                        University of Pennsylvania
                      </p>
                      <p className="mb-1 text-secondary">
                        Office of Admissions
                      </p>
                      <p className="mb-1 text-secondary">
                        3535 Market Street, Suite 850
                      </p>
                      <p className="mb-1 text-secondary">
                        Philadelphia, PA 19104
                      </p>
                    </div>
                    <div className="mt-4 mb-4">
                      <p className="font-bold font-16 mb-2">
                        Admissions visitor center{" "}
                      </p>
                      <p className="mb-1 text-secondary">
                        Claudia Cohen Hall, Ground Floor
                      </p>
                      <p className="mb-1 text-secondary">249 S. 36th Street</p>
                      <p className="mb-1 text-secondary">
                        Philadelphia, PA 19104
                      </p>
                    </div>
                    <div>
                      <div className="mb-3">
                        <p className="mb-1 font-16">Live Student Chats</p>
                        <div className="d-flex text-brand align-items-center gap-2 opacity-75">
                          <img src={calendarIconColor} width={15} alt="" />
                          <span>Thu, Nov 9, 2023</span>
                          <span>8:00 PM</span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <p className="mb-1 font-16">Live Student Chats</p>
                        <div className="d-flex text-brand align-items-center gap-2 opacity-75">
                          <img src={calendarIconColor} width={15} alt="" />
                          <span>Thu, Nov 9, 2023</span>
                          <span>8:00 PM</span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <p className="mb-1 font-16">
                          Native and Indigenous Excellence at Penn
                        </p>
                        <div className="d-flex text-brand align-items-center gap-2 opacity-75">
                          <img src={calendarIconColor} width={15} alt="" />
                          <span>Thu, Nov 9, 2023</span>
                          <span>8:00 PM</span>
                        </div>
                      </div>
                      <div className=" ">
                        <p className="mb-1 font-16">Live Student Chats</p>
                        <div className="d-flex text-brand align-items-center gap-2 opacity-75">
                          <img src={calendarIconColor} width={15} alt="" />
                          <span>Thu, Nov 9, 2023</span>
                          <span>8:00 PM</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col lg={9}>
            {showLastStep && (
              <Card className="card-shadow mb-4 p-4">
                <Card className="bg-light p-2">
                  {!showDoneStep ? (
                    <div className="d-flex gap-4">
                      <div>
                        <svg
                          width="40"
                          height="38"
                          viewBox="0 0 40 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M31.8378 23.64C31.3198 24.142 31.0818 24.868 31.1998 25.58L32.9778 35.42C33.1278 36.254 32.7758 37.098 32.0778 37.58C31.3938 38.08 30.4838 38.14 29.7378 37.74L20.8798 33.12C20.5718 32.956 20.2298 32.868 19.8798 32.858H19.3378C19.1498 32.886 18.9658 32.946 18.7978 33.038L9.93775 37.68C9.49975 37.9 9.00375 37.978 8.51775 37.9C7.33375 37.676 6.54375 36.548 6.73775 35.358L8.51775 25.518C8.63575 24.8 8.39775 24.07 7.87975 23.56L0.657752 16.56C0.0537516 15.974 -0.156248 15.094 0.119752 14.3C0.387752 13.508 1.07175 12.93 1.89775 12.8L11.8378 11.358C12.5938 11.28 13.2578 10.82 13.5978 10.14L17.9778 1.16C18.0818 0.96 18.2158 0.776 18.3778 0.62L18.5578 0.48C18.6518 0.376 18.7598 0.29 18.8798 0.22L19.0978 0.14L19.4378 0H20.2798C21.0318 0.078 21.6938 0.528 22.0398 1.2L26.4778 10.14C26.7978 10.794 27.4198 11.248 28.1378 11.358L38.0778 12.8C38.9178 12.92 39.6198 13.5 39.8978 14.3C40.1598 15.102 39.9338 15.982 39.3178 16.56L31.8378 23.64Z"
                            fill="#5C5FA1"
                          />
                        </svg>
                      </div>
                      <div>
                        <h3 className="font-16 font-bold">One last step!</h3>
                        <p className="font-14 text-secondary font-light">
                          {requireDocObj?.button_type == "UPLOAD"
                            ? "Upload"
                            : "Request"}{" "}
                          your{" "}
                          {requireDocObj.name_of_document
                            ? requireDocObj.name_of_document.replace(
                              /\(\w+\)/g,
                              ""
                            )
                            : ""}{" "}
                          and you’re all set!!
                        </p>
                        <Button
                          className="btn-brand-1 mb-3"
                          type="button"
                          onClick={() => {
                            requireDocObj?.button_type == "UPLOAD"
                              ? handleShowUpload(requireDocObj.id)
                              : handleRequestModal(requireDocObj);
                          }}
                          id="save_file_btn"
                        >
                          {requireDocObj?.button_type == "UPLOAD"
                            ? "Upload"
                            : "Request"}{" "}
                          {requireDocObj.name_of_document
                            ? requireDocObj.name_of_document.replace(
                              /\(\w+\)/g,
                              ""
                            )
                            : ""}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex gap-4 align-items-center">
                      <div>
                        {" "}
                        <img
                          src={iconGoodJob}
                          width={71}
                          height={71}
                          alt=""
                        />{" "}
                      </div>
                      <div>
                        <h3 className="font-16 font-bold">Good job!</h3>
                        <p className="font-14 text-secondary font-light mb-0">
                          You’ve completed all requirements and ready to apply!{" "}
                        </p>
                      </div>
                    </div>
                  )}
                </Card>
              </Card>
            )}

            <Card className="card-shadow mb-4">
              <Card.Body className="p-4">
                {isLoadingDocument ? (
                  <CardLoader />
                ) : (
                  <>
                    <p className="font-bold font-16 mb-4">Required documents</p>
                    <ul className="font-medium ps-3 font-16 lh-lg mb-4">
                      {requireDocList.map((item: any) => {
                        return (
                          <li key={item.uuid}>
                            <div className="d-flex justify-content-between ">
                              <div>
                                <span>{item.name_of_document}</span>
                                <br />
                                <div className="d-flex gap-3 flex-wrap">
                                  {item.name_of_document
                                    .toLowerCase()
                                    .includes("recommendation") &&
                                    item.documents &&
                                    !!item.documents.length &&
                                    item.documents.map((doc: any) => {
                                      return (
                                        <Card
                                          onMouseEnter={() => setIsHover(true)}
                                          onMouseLeave={() => setIsHover(false)}
                                          className="py-2 px-3 uploaded-doc-card"
                                        >
                                          <div className="d-flex gap-2 align-items-center">
                                            <div className="">
                                              <IoClose
                                                size={20}
                                                onClick={() => {
                                                  removeFile(doc.uuid);
                                                }}
                                                className="text-secondary opacity-50 hover-effect cursor-pointer close"
                                              />
                                            </div>
                                            <div>
                                              <img
                                                src={DocIcon}
                                                width={40}
                                                height={40}
                                                alt=""
                                              />
                                            </div>
                                            <div>
                                              <p className="font-medium font-16 mb-0 text-break">
                                                {doc.document_name}
                                              </p>
                                              <span className="text-secondary">
                                                {doc.file_size}
                                              </span>
                                            </div>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                </div>
                              </div>
                              <div>
                                {item.button_type == "UPLOAD" && (
                                  <>
                                    {item.maximum &&
                                      item.documents &&
                                      item.maximum == item.documents.length && (
                                        <FaCheck
                                          size={16}
                                          className="text-success "
                                        />
                                      )}
                                    {item.documents.length == 0 && (
                                      <Link
                                        to=""
                                        className="text-link font-medium text-brand"
                                        onClick={() =>
                                          handleShowUpload(item.id)
                                        }
                                      >
                                        Upload
                                      </Link>
                                    )}
                                    {item.maximum &&
                                      item.documents &&
                                      item.maximum > item.documents.length &&
                                      item.minimum < item.documents.length && (
                                        <Link
                                          to=""
                                          className="text-link font-medium text-success"
                                          onClick={() =>
                                            handleShowUpload(item.id)
                                          }
                                        >
                                          Upload
                                        </Link>
                                      )}
                                  </>
                                )}
                                {item.button_type == "REQUEST" && (
                                  <>
                                    {item.is_requested &&
                                      item.maximum &&
                                      item.documents &&
                                      item.maximum <= item.documents.length && (
                                        <FaCheck
                                          size={16}
                                          className="text-success "
                                        />
                                      )}
                                    {item.is_requested &&
                                      item.documents &&
                                      item.documents.length < item.maximum && (
                                        <Link
                                          to=""
                                          className="text-link font-medium text-success"
                                          onClick={() => {
                                            handleRequestModal(item);
                                          }}
                                        >
                                          Request
                                        </Link>
                                      )}
                                    {item.documents.length == 0 &&
                                      !item.is_requested && (
                                        <Link
                                          to=""
                                          className="text-link font-medium text-brand"
                                          onClick={() => {
                                            handleRequestModal(item);
                                          }}
                                        >
                                          Request
                                        </Link>
                                      )}

                                    {/* {(item.maximum && item.documents && (item.maximum > item.documents.length) && (item.minimum < item.documents.length) || item.is_requested) && <Link to="" className="text-link font-medium text-success" onClick={() => { handleRequestModal(item) }}>Request</Link>} */}
                                  </>
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>

                    <p className="font-bold font-16 mb-4">Deadlines and fee </p>
                    <ul className="font-medium ps-3 font-16 lh-lg mb-0">
                      <li>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Early decision</span>
                          <span className=" font-14 text-secondary font-light ">
                            {" "}
                            November 1, 2022{" "}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Regular decision</span>
                          <span className=" font-14 text-secondary font-light">
                            January 5, 2023
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Fee</span>
                          <span className=" font-14 text-secondary font-light">
                            $75 or fee waiver for those who qualify
                          </span>
                        </div>
                      </li>
                    </ul>
                  </>
                )}
              </Card.Body>
            </Card>
            <Card className="card-shadow mb-3">
              <Card.Body className="p-4">
                {isLoading ? (
                  <CardLoader />
                ) : (
                  <>
                    <p className="font-bold font-16">Your chances</p>
                    <Row className=" align-items-center">
                      <Col
                        className="liq-progress"
                        style={{ maxWidth: "190px" }}
                      >
                        {/* <img src={graph} width="100%" alt="" /> */}
                        <div className="progress1" role="progressbar">
                          <div className="progress-inner">
                            <div className="progress-indicator"></div>
                            <div className="progress-indicator"></div>
                          </div>
                          <span className="progress-label progress--upper-half-value">
                            <strong>{manageLiquidAnimation(50)}</strong>
                            <span>%</span>
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <Card className="rounded-4 bg-light p-4">
                          <div className="d-lg-flex  gap-3 justify-content-between">
                            <div className="d-lg-flex font-16 gap-3 mb-lg-0 mb-2">
                              <ul className="mb-0 ps-md-4 ps-3">
                                <li className="font-medium">
                                  Your chances{" "}
                                  <span className="text-brand ">82.4%</span>
                                </li>
                              </ul>
                              <ul className="mb-0 ps-md-4 ps-3">
                                <li className="font-medium">
                                  Overall acceptance rate{" "}
                                  <span className="text-brand ">4%</span>
                                </li>
                              </ul>
                            </div>
                            <div className="text-lg-end">
                              <a
                                href="javascript:void(0)"
                                className="text-link font-16"
                              >
                                See why
                              </a>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}
              </Card.Body>
            </Card>
            {pageStatus &&
              (pageStatus.aboutMe < 100 ||
                pageStatus.extracurricularActivities < 100 ||
                pageStatus.myEducation < 100 ||
                pageStatus.myFamily < 100 ||
                pageStatus.standarizedTest < 100) && (
                <Card className="card-shadow mb-3">
                  <Card.Body className="p-4">
                    {/* About me */}
                    {pageStatus.aboutMe < 100 && (
                      <>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="font-medium font-16">About Me</p>
                          <p className=" text-secondary font-light">
                            {6 -
                              getIncompleteCount([
                                pageStatus?.isCompletePersonalInformation,
                                pageStatus?.isCompleteAddress,
                                pageStatus?.isCompleteContactDetail,
                                pageStatus?.isCompleteDemographics,
                                pageStatus?.isCompleteLanguage,
                                pageStatus?.isCompleteGeography,
                              ])}
                            /6 completed
                          </p>
                        </div>
                        <div className="pb-2">
                          <Accordion className="detais-accordion mb-4">
                            {pageStatus?.isCompletePersonalInformation != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Contact"
                                onClick={() =>
                                  navigate("/my-details/about-me#basics")
                                }
                              >
                                <Accordion.Header>The basics</Accordion.Header>
                                <Accordion.Body> </Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteAddress != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Location"
                                onClick={() =>
                                  navigate("/my-details/about-me#address")
                                }
                              >
                                <Accordion.Header>Address</Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteContactDetail != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Location1"
                                onClick={() =>
                                  navigate("/my-details/about-me#contact")
                                }
                              >
                                <Accordion.Header>Contact info </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteDemographics != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Location2"
                                onClick={() =>
                                  navigate("/my-details/about-me#background")
                                }
                              >
                                <Accordion.Header>
                                  Background information
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteLanguage != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Location3"
                                onClick={() =>
                                  navigate("/my-details/about-me#language")
                                }
                              >
                                <Accordion.Header>
                                  Language(s) I understand
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteGeography != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Location4"
                                onClick={() =>
                                  navigate("/my-details/about-me#location")
                                }
                              >
                                <Accordion.Header>
                                  Language(s) I understand
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                          </Accordion>
                        </div>
                      </>
                    )}

                    {pageStatus.myFamily < 100 && (
                      <>
                        {/* My Family */}
                        <div className="d-flex justify-content-between mb-2">
                          <p className="font-medium font-16">My Family</p>
                          <p className=" text-secondary font-light">
                            {4 -
                              getIncompleteCount([
                                pageStatus?.isCompleteHouseHold,
                                pageStatus?.isCompleteParent1,
                                pageStatus?.isCompleteParent2,
                                pageStatus?.isCompleteSibling,
                              ])}
                            /4 completed
                          </p>
                        </div>
                        <div className="pb-2">
                          <Accordion className="detais-accordion mb-4">
                            {pageStatus?.isCompleteHouseHold != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Family"
                                onClick={() =>
                                  navigate("/my-details/family#living")
                                }
                              >
                                <Accordion.Header>
                                  Living situation
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteParent1 != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Family1"
                                onClick={() =>
                                  navigate("/my-details/family#1stparent")
                                }
                              >
                                <Accordion.Header>1st Parent</Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteParent2 != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Family2"
                                onClick={() =>
                                  navigate("/my-details/family#2ndparent")
                                }
                              >
                                <Accordion.Header>2nd Parent</Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteSibling != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Family3"
                                onClick={() =>
                                  navigate("/my-details/family#siblings")
                                }
                              >
                                <Accordion.Header>Siblings</Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                          </Accordion>
                        </div>
                      </>
                    )}

                    {pageStatus.myEducation < 100 && (
                      <>
                        {/* My Education Detail */}
                        <div className="d-flex justify-content-between mb-2">
                          <p className="font-medium font-16">
                            My Education Details
                          </p>
                          <p className=" text-secondary font-light">
                            {8 -
                              getIncompleteCount([
                                pageStatus?.isCompleteCurrentSchool,
                                pageStatus?.isCompleteAdditionalSchool,
                                pageStatus?.isCompleteCourseCollegeUniversities,
                                pageStatus?.isCompleteGpa,
                                pageStatus?.isCompleteCourseGrade,
                                pageStatus?.isCompleteHonorsRecognition,
                                pageStatus?.isCompleteCommunityOrganizations,
                                pageStatus?.isCompleteFuturePlan,
                              ])}
                            /8 completed
                          </p>
                        </div>
                        <div className="pb-2">
                          <Accordion className="detais-accordion mb-4">
                            {pageStatus?.isCompleteCurrentSchool != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Familye1"
                                onClick={() =>
                                  navigate("/my-details/education#recent")
                                }
                              >
                                <Accordion.Header>
                                  Most recent or current high/secondary school
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteAdditionalSchool != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Familye2"
                                onClick={() =>
                                  navigate("/my-details/education#additional")
                                }
                              >
                                <Accordion.Header>
                                  Additional high/secondary schools
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteCourseCollegeUniversities != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Family2e3"
                                onClick={() =>
                                  navigate("/my-details/education#courses")
                                }
                              >
                                <Accordion.Header>
                                  Courses at Colleges or Universities
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteGpa != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Family3e4"
                                onClick={() =>
                                  navigate("/my-details/education#gpa")
                                }
                              >
                                <Accordion.Header>GPA</Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteCourseGrade != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Familye5"
                                onClick={() =>
                                  navigate("/my-details/education#grades")
                                }
                              >
                                <Accordion.Header>
                                  Courses & grades
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteHonorsRecognition != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Family1e6"
                                onClick={() =>
                                  navigate("/my-details/education#honors")
                                }
                              >
                                <Accordion.Header>
                                  Honors & Recognitions
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteCommunityOrganizations != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Family2e7"
                                onClick={() =>
                                  navigate("/my-details/education#community")
                                }
                              >
                                <Accordion.Header>
                                  Community-based organisations
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                            {pageStatus?.isCompleteFuturePlan != "COMPLETED" && (
                              <Accordion.Item
                                eventKey="Family3e8"
                                onClick={() =>
                                  navigate("/my-details/education#career")
                                }
                              >
                                <Accordion.Header>
                                  Career aspirations
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}
                          </Accordion>
                        </div>
                      </>
                    )}

                    {pageStatus.standarizedTest < 100 && (
                      <>
                        {/* Standardized Tests */}
                        <div className="d-flex justify-content-between mb-2">
                          <p className="font-medium font-16">
                            Standardized Tests
                          </p>
                          <p className=" text-secondary font-light">
                            {testDatail.length + 1 - getIncompleteTestCount()}/
                            {testDatail.length + 1} completed
                          </p>
                        </div>
                        <div className="pb-2">
                          <Accordion className="detais-accordion mb-4">
                            {!pageStatus.isCompleteTestTaken && (
                              <Accordion.Item
                                eventKey="Familyetest1"
                                onClick={() => navigate("/my-details/tests")}
                              >
                                <Accordion.Header>Tests taken</Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            )}

                            {testDatail &&
                              testDatail.length > 0 &&
                              testDatail.map((item: any) => {
                                return (
                                  <>
                                    {item == "ACT_TEST" &&
                                      pageStatus.isCompleteACT != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="Familyetest2sfs3"
                                          onClick={() =>
                                            navigate("/my-details/tests#ACTTests")
                                          }
                                        >
                                          <Accordion.Header>
                                            ACT Tests
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    {item == "SAT_TEST" &&
                                      pageStatus.isCompleteSAT != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="Familyetest2SATsfs3"
                                          onClick={() =>
                                            navigate("/my-details/tests#SATTests")
                                          }
                                        >
                                          <Accordion.Header>
                                            SAT Tests
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    {item == "PSAT_TEST" &&
                                      pageStatus.isCompletePSAT != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="Familyetest2SPSATATsfs3"
                                          onClick={() =>
                                            navigate(
                                              "/my-details/tests#PSATTests"
                                            )
                                          }
                                        >
                                          <Accordion.Header>
                                            PSAT
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    {item == "AP_SUBJECT_TEST" &&
                                      pageStatus.isCompleteApExam != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="FamilyetesAPSubjectTestsATsfs3"
                                          onClick={() =>
                                            navigate(
                                              "/my-details/tests#APSubjectTests"
                                            )
                                          }
                                        >
                                          <Accordion.Header>
                                            AP subject test
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    {item == "IB_SUBJECT_TEST" &&
                                      pageStatus.isCompleteIbSubjectTest != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="FamilyetesIBSubjectTestsfs3"
                                          onClick={() =>
                                            navigate(
                                              "/my-details/tests#IBSubjectTests"
                                            )
                                          }
                                        >
                                          <Accordion.Header>
                                            IB Subject Tests
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    {item == "CAMBRIDGE_TEST" &&
                                      pageStatus.isCompleteCambridgeTest != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="FamilyeCambridgefs3"
                                          onClick={() =>
                                            navigate(
                                              "/my-details/tests#Cambridge"
                                            )
                                          }
                                        >
                                          <Accordion.Header>
                                            Cambridge
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    {item == "TOEFL_IBT_TEST" &&
                                      pageStatus.isCompleteToeflTest != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="FamilyTOEFLiBTfs3"
                                          onClick={() =>
                                            navigate("/my-details/tests#TOEFLiBT")
                                          }
                                        >
                                          <Accordion.Header>
                                            TOEFL iBT
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    {item == "PTE_ACADEMIC_TEST" &&
                                      pageStatus.isCompletePteTest != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="FamilyePTEAcademicTests3"
                                          onClick={() =>
                                            navigate(
                                              "/my-details/tests#PTEAcademicTest"
                                            )
                                          }
                                        >
                                          <Accordion.Header>
                                            PTE Academic Test
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    {item == "IELTS_TEST" &&
                                      pageStatus.isCompleteIeltsTest != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="FamilIELTSridgefs3"
                                          onClick={() =>
                                            navigate("/my-details/tests#IELTS")
                                          }
                                        >
                                          <Accordion.Header>
                                            IELTS
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                    {item == "DUOLINGO_TEST" &&
                                      pageStatus.isCompleteDuolingoTest != "COMPLETED" && (
                                        <Accordion.Item
                                          eventKey="FamilDuolingoEnglishTestefs3"
                                          onClick={() =>
                                            navigate(
                                              "/my-details/tests#DuolingoEnglishTest"
                                            )
                                          }
                                        >
                                          <Accordion.Header>
                                            Duolingo English Test
                                          </Accordion.Header>
                                          <Accordion.Body></Accordion.Body>
                                        </Accordion.Item>
                                      )}
                                  </>
                                );
                              })}
                          </Accordion>
                        </div>
                      </>
                    )}

                    {pageStatus.extracurricularActivities < 100 && (
                      <>
                        {/* Extracurriculars & Activities */}
                        <div className="d-flex justify-content-between mb-2">
                          <p className="font-medium font-16">
                            Extracurriculars & Activities
                          </p>
                          <p className=" text-secondary font-light">
                            1/1 completed
                          </p>
                        </div>
                        <div className="pb-2">
                          <Accordion className="detais-accordion mb-4">
                            <Accordion.Item
                              eventKey="Familyeextra1"
                              onClick={() =>
                                navigate("/my-details/extracurriculars")
                              }
                            >
                              <Accordion.Header>
                                Extracurriculars & Activities
                              </Accordion.Header>
                              <Accordion.Body></Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </>
                    )}

                    {pageStatus.wrtingSample < 100 && (
                      <>
                        {/* Writing Samples */}
                        <div className="d-flex justify-content-between mb-2">
                          <p className="font-medium font-16">Writing Samples</p>
                          <p className=" text-secondary font-light">
                            {2 -
                              getIncompleteCount([
                                pageStatus?.isCompletePersonalEssay,
                                pageStatus?.isCompleteAdditionalInformation,
                              ])}
                            /2 completed
                          </p>
                        </div>
                        <Accordion className="detais-accordion mb-4">
                          {pageStatus?.isCompletePersonalEssay != "COMPLETED" && (
                            <Accordion.Item
                              eventKey="Familyeextra1Essay"
                              onClick={() =>
                                navigate("/my-details/writing-samples")
                              }
                            >
                              <Accordion.Header>
                                Personal Essay
                              </Accordion.Header>
                              <Accordion.Body></Accordion.Body>
                            </Accordion.Item>
                          )}
                          {pageStatus?.isCompleteAdditionalInformation != "COMPLETED" && (
                            <Accordion.Item
                              eventKey="FamilyeextrEssaya1"
                              onClick={() =>
                                navigate(
                                  "/my-details/writing-samples#additionalInfo"
                                )
                              }
                            >
                              <Accordion.Header>
                                Additional Information
                              </Accordion.Header>
                              <Accordion.Body></Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </>
                    )}
                  </Card.Body>
                </Card>
              )}
          </Col>
        </Row>
      </div>

      {/* Request school report */}
      <UploadDocumentModal
        show={showUpload}
        id={id || ""}
        isClose={(e: boolean) => {
          handleCloseUpload();
          if (e) {
            getRequiredDocList();
            getPageStatus();
          }
        }}
        type={uploadId}
      />
      <RequestSchoolReportModal
        show={showReqRepo}
        isClose={(e: boolean) => {
          handleCloseReqRepo();
          e && getRequiredDocList();
        }}
        type={requestType}
        id={id || ""}
        doc_id={uploadId}
      />
    </>
  );
};

export default ReqUniversityDetails;
