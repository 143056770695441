import { Card, Row, Col, Tab, Dropdown } from "react-bootstrap"
import menuIcon from "../../../assets/images/menu-icon.svg"
import NavTabs from "./NavTabs";
import PersonalInfo from "../../../components/my-details/PersonalInfo";
import AddressInfo from "../../../components/my-details/AddressInfo";
import { useEffect, useState } from "react";
import PhoneInfo from "../../../components/my-details/PhoneInfo";
import DemographicInfo from "../../../components/my-details/DemographicInfo";
import Language from "../../../components/my-details/Language";
import Geography from "../../../components/my-details/Geography";
import WebService from "../../../Services/WebService";
import { useLocation, useNavigate } from "react-router-dom";
import '../my-details.scss'

const hashData = ["basics", "address", "contact", "background", "language", "location"];

const MyDetailAboutMe = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<string>('basics');
    const [pageStatus, setPageStatus] = useState<any>({
        "isCompletePersonalInformation": false,
        "isCompleteAddress": false,
        "isCompleteContactDetail": false,
        "isCompleteDemographics": false,
        "isCompleteLanguage": false,
        "isCompleteGeography": false
    });
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [confirmTabChange, setConfirmTabChange] = useState<string>('');

    useEffect(() => {
        getPageStatus();
        if (location.hash) {
            hashData.map((item: any) => {
                if (item == location.hash.replaceAll("#", "")) {
                    setActiveTab(item);
                }
            })
        };
        var PageHeading: any = document.getElementById("page_title");
        PageHeading.textContent = "About Me";
        var BackArrow: any = document.getElementById("back_nav");
        BackArrow.classList.add("d-block");
        // BackArrow.onclick = (() => { navigate("/my-details") });
        BackArrow.onclick = (() => { window.history.back() });
        return () => {
            PageHeading.textContent = "";
            BackArrow.classList.remove("d-block");
            BackArrow.onclick = (() => { window.history.back() });
        };
        // eslint-disable-next-line
    }, []);

    const getPageStatus = () => {
        WebService.getAPI({ action: "get/personal-detail?type=PROFILE" })
            .then((res: any) => {
                if (res.result) {
                    setPageStatus(res.result);
                }
            })
            .catch((e: any) => { });
    };

    const onChangeTab = (tab: string) => {
        if (isFormDirty) {
            setConfirmTabChange(tab);
        } else {
            setActiveTab(tab);
            navigate(`#${tab}`, { replace: true });
        }
    };

    const updateFormStatus = (e: boolean, tab: string) => {
        if (tab == activeTab) {
            setIsFormDirty(e);
        }
    };


    return (
        <>
            <div className="page-my-details page-container">
                <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                    <Row className="gx-0">
                        <Col className="border-end border-light">
                            <Card className="card-shadow form-style-2 rounded-end-0 card-padding h-100 about-card-min-h mo-no-card-style">
                                <Tab.Content>
                                    <Tab.Pane eventKey="basics">
                                        {activeTab == "basics" && <PersonalInfo isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="address">
                                        {activeTab == "address" && <AddressInfo isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="contact">
                                        {activeTab == "contact" && <PhoneInfo isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="background">
                                        {activeTab == "background" && <DemographicInfo isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="language">
                                        {activeTab == "language" && <Language isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="location">
                                        {activeTab == "location" && <Geography isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                </Tab.Content>

                            </Card>
                        </Col>
                        <Col lg={4} className="about-tab-col">
                            <div className="d-lg-block d-none d-flex h-100">
                                <NavTabs activeTab={activeTab} changeTab={(tab: string) => { onChangeTab(tab); }} status={pageStatus} />
                            </div>
                            <Dropdown className="d-lg-none d-block mob-tab-menu">
                                <Dropdown.Toggle className="btn btn-tabs-nav"><img src={menuIcon} width={18} alt="" /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <NavTabs activeTab={activeTab} changeTab={(tab: string) => { onChangeTab(tab); }} status={pageStatus} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}
export default MyDetailAboutMe;