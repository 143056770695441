import { Row, Col, Button, Form } from "react-bootstrap"
import { Controller, useForm, useFormState } from "react-hook-form";
import OpenTagSelect from "../Common/OpenTagSelect/OpenTagSelect";
import { Options } from "../Common/OpenTagSelect/OpenTagSelect";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { useBlocker, useNavigate } from "react-router-dom";
import HelperService from "../../Services/HelperService";
import ExitConfirmModal from "./ExitConfirmModal";

const examOption: Options[] = [
    { id: "ACT_TEST", value: "ACT Tests" },
    { id: "SAT_TEST", value: "SAT Tests" },
    { id: "PSAT_TEST", value: "PSAT Tests" },
    { id: "AP_SUBJECT_TEST", value: "AP Subject Tests" },
    { id: "IB_SUBJECT_TEST", value: "IB Subject Tests" },
    { id: "CAMBRIDGE_TEST", value: "Cambridge" },
    { id: "TOEFL_IBT_TEST", value: "TOEFL iBT" },
    { id: "PTE_ACADEMIC_TEST", value: "PTE Academic Test" },
    { id: "IELTS_TEST", value: "IELTS" },
    { id: "DUOLINGO_TEST", value: "Duolingo English Test" },
];

interface propData {
    tab: string;
    data: any;
    isUpdate: Function;
    isFormDirty: Function;
    confirmTabChange: string;
    updateActiveTab: Function;
}

const TabTestsTaken = (props: propData) => {
    const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });
    const navigate = useNavigate();
    const { dirtyFields } = useFormState({ control });
    const watchAllFields = watch();
    const [confirmExit, setConfirmExit] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return props.tab == "testsTaken" && (!HelperService.isEmptyObject(dirtyFields) || isUpdate) && currentLocation.pathname !== nextLocation.pathname;
    });

    useEffect(() => {
        if (props.tab == "testsTaken" && (!HelperService.isEmptyObject(dirtyFields) || isUpdate)) {
            props.isFormDirty(true, 'testsTaken');
        } else {
            props.isFormDirty(false, 'testsTaken');
        }
    }, [dirtyFields, watchAllFields, isUpdate]);

    useEffect(() => {
        if (props.tab == "testsTaken" && props.confirmTabChange) {
            setConfirmExit(true);
        }
    }, [props.confirmTabChange]);

    useEffect(() => {
        if (props.tab == "testsTaken") {
            reset(props.data);
            if (props.data?.future_already_completed && props.data?.future_already_completed.length) {
                let temp: Options[] = []
                props.data.future_already_completed.map((ite: any) => {
                    examOption.map((res: Options) => {
                        if (ite == res.id) {
                            temp.push(res);
                        }
                    })
                });
                setValue("future_already_completed", temp)
            }
        }
    }, [props.data, props.tab]);

    const onSave = async (data: any) => {
        if (data.future_already_completed && data.future_already_completed.length) {
            data.future_already_completed = data.future_already_completed.map((item: any) => {
                return item.id
            })
        }
        await WebService.postAPI({
            action: "save/standarized-test",
            body: data,
            id: "test_btn",
        })
            .then((res: any) => {
                setIsUpdate(false);
                toast.success(res.message);
                props.isUpdate();
                let tempData = { ...watchAllFields };
                reset({});
                reset(tempData);
            })
            .catch((e: any) => { });
    };


    return (
        <>
            <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
                if (e) {
                    WebService.addLoader('Save_and_exit_btn');
                    await onSave(watch());
                    WebService.removeLoader('Save_and_exit_btn');
                }
                reset({});
                blocker.state === "blocked" && blocker.proceed();
                if (confirmExit) {
                    navigate(`#${props.confirmTabChange}`, { replace: true });
                    props.updateActiveTab(props.confirmTabChange);
                    setConfirmExit(false);
                }
            }} />
            <form onSubmit={handleSubmit(onSave)}>
                <Row>
                    <Col style={{ maxWidth: "624px" }}>
                        <h4 className="font-medium font-20 mb-3 pb-1">Tests taken</h4>
                        <Form className="form-style-2">
                            <Form.Group className="mb-3">
                                <p>Would you like to provide information on your own regarding scores or upcoming examination dates for any of these standardized assessments: ACT, SAT, AP, IB, Cambridge, TOEFL, PTE Academic, IELTS, and Duolingo English Test, in conjunction with submitting the official score reports as mandated by universities?<span className="text-danger">*</span></p>
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="Yes" 
                                    value={"YES"}
                                    {...register("is_submit_official_score_reports", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Decile"
                                    label="No"
                                    value={"NO"}
                                    {...register("is_submit_official_score_reports", { required: false })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <p> Please include each exam you intend to report, including the ones you plan to take in the future and those you have already completed.<span className="text-danger">*</span></p>
                                <Controller
                                    control={control}
                                    name={`future_already_completed`}
                                    rules={{ required: false }}
                                    render={({ field }: any) => (
                                        <>
                                            <OpenTagSelect
                                                options={examOption}
                                                selected={watchAllFields.future_already_completed ? watchAllFields.future_already_completed : []}
                                                onChange={(data: any) => { !isUpdate && setIsUpdate(true); setValue("future_already_completed", data); }}
                                                selectLimit={examOption.length}
                                                isCustom={false}
                                                placeholder="Choose an option"
                                            />
                                        </>
                                    )}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <p>For students applying from outside the United States: Does advancement in your educational system rely on standardized exit exams administered by a regional or national examinations board after lower and/or upper secondary education? (It is common for students educated in the US to respond "No" to this section.)</p>
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="Yes"
                                    value={"YES"}
                                    {...register("regional_or_national_examination", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Decile"
                                    label="No"
                                    value={"NO"}
                                    {...register("regional_or_national_examination", { required: false })}
                                />
                            </Form.Group>
                        </Form>
                        <div className="text-center">
                            <Button type="submit" id="test_btn" className="btn btn-brand-1 w-100">
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    )
}
export default TabTestsTaken;
